import React, { useEffect } from 'react';

import { Waypoint } from 'react-waypoint';

import { useHistory, useParams } from 'react-router';

import { Button, CardActions, Grid, Paper } from '@material-ui/core';

import OBIReportDetails from './Details';
import Loader from '../../components/Loader';
import OBIReportsList from './OBIReportsList';
import EmptyList from '../../components/EmptyList';
import useColumns, { IColumn } from '../../hooks/useColumns';
import { IOBIReport, useOBIReportsList } from '../../api/reports';
import useGlobalStyles from '../../components/Layout/useGlobalStyles';

type IParams = {
  reportId: string
}

const defaultColumns: IColumn[] = [
  { id: 'id', name: 'ID', active: true },
  { id: 'businessName', name: 'Business Name', active: true },
  { id: 'email', name: 'Email', active: true },
  { id: 'executedOrdersCount', name: 'Executed orders count', active: true },
  { id: 'executedOrdersSum', name: 'Executed orders amount', active: true },
  { id: 'created', name: 'Created at', active: true },
  { id: 'updated', name: 'Updated at', active: true },
];

const OBIReportsPage = () => {
  const { reportId } = useParams<IParams>();
  const history = useHistory();

  const classes = useGlobalStyles();

  const [columns, ColumnsFilter] = useColumns('reports', defaultColumns);

  const [{ items, cursor, full, error }, loading, load] = useOBIReportsList();

  function setDetails(id?: string) {
    history.push(`/settings/obi-reports${id ? `/${id}` : ''}`);
  }

  useEffect(() => {
    load({
      cursor: 0
    });
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item className={classes.table} style={{ width: '100%', minWidth: 'fit-content' }}>
        <Paper className={classes.paper}>
          <CardActions>
            <Grid container justify="space-between">
              <Grid item>
                <Button type="button" variant="contained" size="large" onClick={() => setDetails('new')}>Create</Button>
              </Grid>
              <Grid item>
                <ColumnsFilter />
              </Grid>
            </Grid>
          </CardActions>
          {items.length > 0
            ? <OBIReportsList items={items} onRowClick={setDetails} columns={columns} />
            : <EmptyList error={error} loading={loading} />}
          <Loader loading={loading} />
          {!loading && !error && !full && <Waypoint onEnter={() => load({ cursor })} />}
        </Paper>
      </Grid>
      {reportId && 
        <OBIReportDetails
          updateList={() => load({ cursor: 0 })} 
          onClose={() => history.push('/settings/obi-reports')} 
          report={items.find((report: IOBIReport) => report.id.toString() === reportId)}
        />}
    </Grid>
  );
};

export default OBIReportsPage;