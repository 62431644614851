import React from 'react';

import { Field } from 'react-final-form';

import { TextField, Chip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

interface IAutocompleteFieldWithTags {
  name: string;
  label: string;
}

const AutocompleteFieldWithTags = ({ name, label }: IAutocompleteFieldWithTags) => {
  return (
    <Field name={name}>
      {({ input }) => {
        const handleAddValue = (value: string) => {
          if (value && (!input.value || !input.value.includes(value))) {
            const currentValues = input.value || [];
            const updatedValues = [...currentValues, value];
            input.onChange(updatedValues);
          }
        };

        const handleDeleteValue = (tag: string) => {
          const currentValues = input.value || [];
          const updatedValues = currentValues.filter((value: string) => value !== tag);
          input.onChange(updatedValues);
        };

        return (
          <Autocomplete
            multiple
            freeSolo
            clearOnBlur
            options={[]}
            value={input.value || []}
            onChange={(event, options, reason) => {
              if (reason === 'select-option') {
                const newInputValue = options[options.length - 1].trim();
                const parsedInputValue = 
                  newInputValue.startsWith('Add') ? newInputValue.slice(4) : newInputValue;
                handleAddValue(parsedInputValue);
              }
            }}
            renderTags={(value: string[], getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  size="small"
                  label={option}
                  {...getTagProps({ index })}
                  onDelete={() => handleDeleteValue(option)}
                />
              ))
            }
            filterOptions={(options, { inputValue }) => {
              return inputValue ? [`Add ${inputValue}`] : [];
            }}
            renderOption={(option) => option}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label={label} placeholder="Add ID" />
            )}
          />
        );
      }}
    </Field>
  );
};

export default AutocompleteFieldWithTags;