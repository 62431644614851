import React, { useEffect, useState } from 'react';

import { Form } from 'react-final-form';

import { useRouteMatch } from 'react-router';

import clsx from 'clsx';

import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ACLType } from '../../../api/acl';
import { ICustomer, useCountryReset, useStateReset } from '../../../api/customers';
import { useKycList } from '../../../api/kyc';
import { DataRow } from '../../../components/DataView';
import DateView from '../../../components/DateView';
import { ProtectionInput } from '../../../components/Form';
import BoolLabel from '../../../components/Label/BoolLabel';
import Loader from '../../../components/Loader';
import PopupLink from '../../../components/UI/PopupLink';
import useFormStyles from '../../../hooks/useFormStyles';
import ACLFastAdd, { ACLData } from '../../ACL/BlakWhite/ACLFastAdd';
import { getSumSubUrl } from '../../KYC';
import CustomerSettingsForm from './Overview/SettingsForm';
import Divider from '@material-ui/core/Divider';
import BillingAddressForm from './Overview/BillingAddressForm';
import { LegalEntityOne, useLegalEntityList } from '../../../api/legalEntity';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useCustomerUpdateLegalEntity } from '../../../api/customers';
import { Can } from '../../../acl/can';

interface IProps {
  customer: ICustomer;
  onSave: any;
  resetSMS: any;
  loadCustomer: any;
  smsResetting: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  reset: {
    marginLeft: theme.spacing(2),
    display: 'inline-block'
  },
  protect: {
    flex: '1 1 auto'
  },
  submitEntity: {
    marginRight: 20,
    maxWidth: '100px',
  },
  rowEntity: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '10px',
    },
  },
  defaultEntity: {
    marginRight: '20px',
    display: 'inline-block'
  },
  selectEntity: {
    width: '150px',
  },
  email: {
    margin:'0px 7px 0px 0px',
  },
  row: {
    display: 'flex',
    gap: '5px',
    alignItems: 'flex-start',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column',
    },
  },
  countryRow: {
    display: 'flex',
    [theme.breakpoints.down(700)]: {
      gap: '10px',
    },
  }
}));

export default function CustomerOverviewTab({ customer, onSave, resetSMS, smsResetting, loadCustomer }: IProps) {
  const classes = useStyles();
  const formClasses = useFormStyles();
  const [{ items: kyc }, loading, load] = useKycList();
  const [countryResetting, setCountryResetting] = useState(false);
  const [stateResetting, setStateResetting] = useState(false);
  const [acl, setAcl] = useState<ACLData | undefined>();
  const [{ items: entities, error }, loadingEntity, loadEntity] = useLegalEntityList();
  const currentLegalEntity = entities.find((entity: LegalEntityOne) => entity.id === customer.legalEntity);

  const [customerEntity, customerEntityLoading] = useCustomerUpdateLegalEntity((res: any) => {
    if (res.success) {
      loadCustomer(customer.id);
    }
  });

  const [resetCountry, countryResettingApi] = useCountryReset((res: any) => {
    if (res.success) {
      setCountryResetting(false);
      loadCustomer(customer.id);
    }
  });

  const [resetState, stateResettingApi] = useStateReset((res: any) => {
    if (res.success) {
      setStateResetting(false);
      loadCustomer(customer.id);
    }
  });

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectEntity(event.target.value as any);
  };

  const match = useRouteMatch();

  // find KYC application
  useEffect(() => {
    loadEntity();
    if (customer.id) {
      load({ filter: { customerId: customer.id } });
    }
  }, [customer.id]);

  let kycDetailsBaseUrl = `/${match.url.split('/')[1]}`;

  if (match.url.split('/')[1] === 'acl') {
    const splatUrl = match.url.split('/');
    kycDetailsBaseUrl = `/${splatUrl[1]}/${splatUrl[2]}`;
  }
  const [selectEntity, setSelectEntity] = useState(currentLegalEntity?.name);
  const [entityId, setEntityId] = useState<any>(customer.legalEntity);

  return (
    <Loader loading={loading || customerEntityLoading} width={500}>
      {acl && <ACLFastAdd onClose={() => setAcl(undefined)} value={acl.value} type={acl.type} white={acl.white} />}
      {customer.legalEntity && <Can I="manage" a="customer">
        <DataRow label="Legal Entity">
          <Form
            onSubmit={(values) => {
              customerEntity({ ...values, legalEntityId: entityId, id: customer.id });
            }}
            initialValues={{ '2fa': '' }}
            render={({ handleSubmit, invalid, values }) => (
              <form onSubmit={handleSubmit}>
                <div className={clsx(formClasses.row, classes.rowEntity)}>
                  <span className={classes.defaultEntity}>{currentLegalEntity?.name}</span>
                  <FormControl variant="outlined">
                    <InputLabel id="select-label">Legal Entities</InputLabel>
                    <Select
                      labelId="select--label"
                      id="simple-select"
                      name="legalEntityId"
                      onChange={handleChange}
                      label="Legal Entities"
                      className={classes.selectEntity}
                    >{
                        entities.map((entity: LegalEntityOne) => <MenuItem key={entity.id} onClick={() => setEntityId(entity.id)} value={entity.name}>{entity.name}</MenuItem>)
                      }
                    </Select>
                  </FormControl>
                  <ProtectionInput margin="none" />
                  <Button
                    type="submit"
                    className={classes.submitEntity}
                    disabled={entityId === customer.legalEntity}
                    variant="contained"
                    size="large"
                  >
                    Change
                  </Button>
                </div>
              </form>
            )}
          />
        </DataRow>
      </Can>}
      {
        customer.email && (
          <DataRow label="Email">
            <div className={classes.row}>
              <span className={classes.email}>{customer.email}</span>
              <Can I="manage" a="acl">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setAcl({ type: ACLType.EMAIL, value: customer.email })}
                  size="small"
                >
                  Blacklist
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => setAcl({ type: ACLType.EMAIL, value: customer.email, white: true })}
                >
                  Whitelist
                </Button>
              </Can>
             </div>
          </DataRow>
        )
      }
      {customer.phone && <DataRow label="Phone">{customer.phone}</DataRow>}
      <DataRow label="Created at">
        <DateView value={customer.createdAt} />
      </DataRow>
      <DataRow label="KYC Level">
        {!isNaN(Number(customer.level)) ? customer.level : '-'}
      </DataRow>
      <DataRow label="KYC shared">
        {customer?.kycShared}
      </DataRow>
      <DataRow label="Fully verified">
        <>
          <BoolLabel value={customer.fullVerified} />&nbsp;&nbsp;
          {kyc[0] && (
            <>
              <PopupLink popup="kyc-details" id={kyc[0].id} baseUrl={kycDetailsBaseUrl}>
                Show KYC application
              </PopupLink>
              {kyc[0].flow === 'SUMSUB' && kyc[0].externalApplicationId && (
                <>
                  &nbsp;&nbsp;/&nbsp;&nbsp;
                  <Link href={getSumSubUrl(kyc[0].externalApplicationId)} target="_blank">
                    Open on SumSub
                  </Link>
                </>
              )}
            </>
          )}
        </>
      </DataRow>
      <DataRow label="Country">
        <div className={classes.countryRow}>
          {customer.country || 'N/A'}
            <>
              {countryResetting
                ? (
                  <Form
                    onSubmit={(values) => resetCountry({ id: customer.id, '2fa': values['2fa'] })}
                    initialValues={{ '2fa': '' }}
                    render={({ handleSubmit, invalid, values }) => (
                      <form onSubmit={handleSubmit} className={classes.reset}>
                        <div className={clsx(formClasses.row, classes.countryRow)}>
                          <ProtectionInput margin="none" className={classes.protect} />
                          <Button
                            type="submit"
                            disabled={invalid || countryResettingApi}
                            variant="contained"
                            size="large"
                          >Send</Button>
                        </div>
                      </form>
                    )}
                  />
                ) : (
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => setCountryResetting(true)}
                    className={classes.reset}
                  >Reset</Button>
                )
              }
            </>
        </div>
      </DataRow>
      <DataRow label="State">
      <div className={classes.countryRow}>
          {customer.state || 'N/A'}
          {customer.state && (
            <>
              {stateResetting
                && (
                  <Form
                    onSubmit={(values) => resetState({ id: customer.id, '2fa': values['2fa'] })}
                    initialValues={{ '2fa': '' }}
                    render={({ handleSubmit, invalid, values }) => (
                      <form onSubmit={handleSubmit} className={classes.reset}>
                        <div className={clsx(formClasses.row, classes.countryRow)}>
                          <ProtectionInput margin="none" className={classes.protect} />
                          <Button
                            type="submit"
                            disabled={invalid || stateResettingApi}
                            variant="contained"
                            size="large"
                          >
                            Send
                          </Button>
                        </div>
                      </form>
                    )}
                  />
                )}
              {!stateResetting && (
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => setStateResetting(true)}
                  className={classes.reset}
                >
                  Reset
                </Button>
              )
              }
            </>
          )}
        </div>
      </DataRow>
      {
        customer.settings?.referralBonus !== undefined
        && <DataRow label="Referral bonus">{customer.settings.referralBonus}</DataRow>
      }
      {
        customer.authorities?.includes('ROLE_MERCHANT')
          ? (
            <>
              <CustomerSettingsForm customer={customer} onSave={onSave} />
            </>
          ) : <div />
      }
      <Divider />
      <BillingAddressForm customer={customer} onSave={loadCustomer} />
    </Loader >
  );
}
