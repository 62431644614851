import React, { useContext, useState } from 'react';
import { Form } from 'react-final-form';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DateView from '../../../components/DateView';
import Loader from '../../../components/Loader';
import { ProtectionInput } from '../../../components/Form';
import { IColumn } from '../../../hooks/useColumns';
import useGlobalStyles from '../../../components/Layout/useGlobalStyles';
import { IBinCodeItem, useDeleteBinCodeItem } from '../../../api/binCodes';
import TableCellId from '../../../components/TableCellId/TableCellId';
import Tablecell from '../../../components/TableCell/TableCell';
import useMobileStyles from '../../../hooks/useMobileStyles';
import { AbilityContext, Can } from '../../../acl/can';

interface IProps {
  items: IBinCodeItem[];
  columns: IColumn[];
  onDelete: any;
  onRowClick: any;
}

export default function BinCodesList({ items, columns, onDelete, onRowClick }: IProps) {
  const [del, setDel] = useState<IBinCodeItem | undefined>();
  const globalClasses = useGlobalStyles();
  const mobileClasses = useMobileStyles();
  const [remove, removing] = useDeleteBinCodeItem((res: any) => {
    if (res.success) {
      onDelete(del, 'id', 'delete');
      setDel(undefined);
    }
  });

  const ability = useContext(AbilityContext);
  const activeCols = columns.filter((c) => c.id === 'action' ? ability.can('manage', 'acl') : c.active);

  const content: { [key: string]: (i: IBinCodeItem) => void } = {
    id: ({ id }) => <TableCellId key="id">{id}</TableCellId>,
    createdAt: ({ createdAt }) => <Tablecell title="Created at" key="created"><DateView value={createdAt} /></Tablecell>,
    updatedAt: ({ updatedAt }) => <Tablecell title="Updated at" key="updated"><DateView value={updatedAt} /></Tablecell>,

    binCode: ({ binCode }) => <Tablecell title="Bin Code" key="2">{binCode}</Tablecell>,
    country: ({ country }) => <Tablecell title="Country" key="3">{country}</Tablecell>,
    source: ({ source }) => <Tablecell title="Source" key="4">{source}</Tablecell>,
    adminId: ({ adminId }) => <Tablecell title="Admin Id" key="5">{adminId}</Tablecell>,

    action: (i) => (
      <TableCell key="action">
        <Button
          variant="contained"
          color="secondary"
          onClick={(e: any) => { e.stopPropagation(); setDel(i); }}
          size="small"
        >
          Remove
        </Button>
      </TableCell>
    )
  };

  return (
    <>
      {del && (
        <Dialog open onClose={() => setDel(undefined)}>
          <div className={globalClasses.dialog}>
            <Loader loading={removing} width={450}>
              <Form
                onSubmit={remove}
                initialValues={{ binCode: del.binCode, '2fa': '' }}
                render={({ handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <DialogTitle>Remove item #{del.id}?</DialogTitle>
                    <DialogContent>
                      <DialogContentText>Do you want delete BIN code: {del.binCode}</DialogContentText>
                      <ProtectionInput />
                    </DialogContent>
                    <DialogActions>
                      <Button
                        variant="contained"
                        onClick={() => setDel(undefined)}
                        size="small"
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                        size="small"
                      >
                        Remove
                      </Button>
                    </DialogActions>
                  </form>
                )}
              />
            </Loader>
          </div>
        </Dialog>
      )}
      <Table size="small">
        <TableHead className={mobileClasses.tableHead}>
          <TableRow>
            {activeCols.map((c) => <TableCell key={c.id}>{c.name}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((i) => (
            <TableRow hover key={i.id} className={mobileClasses.row} onClick={() => onRowClick(i.id)}>
              {activeCols.map((c) => content[c.id](i))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}
