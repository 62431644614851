import { AbilityBuilder, defineAbility, createMongoAbility, MongoAbility } from '@casl/ability';

export default defineAbility((can) => {});

export function updateAbility(ability: MongoAbility, user: any) {
  const { can, rules } = new AbilityBuilder(createMongoAbility);



  if (user?.authorities?.includes('ROLE_ADMIN_AUDIT')) {
    can('read', 'kyc');
    can('read', 'customers');
    can('read', 'orders');
    can('read', 'payments');
    can('read', 'kyc-countries');
    can('read', 'acl');
    can('read', 'blockchain');
  } else if (user?.authorities?.includes('ROLE_ADMIN')) {
    can('read', 'all');
    can('manage', 'all');
  }

  ability.update(rules);
}
