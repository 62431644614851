import React from 'react';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import descriptions from './descriptions';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    marginBottom: 2,
    maxWidth: 400,
  },
}))(Tooltip);

interface IStylesProps {
  top?: number;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    [theme.breakpoints.down(700)]: {
      width: '100%'
    },
  },
  desc: ({ top }: IStylesProps) => ({
    color: theme.palette.info.light,
    fontSize: 14,
    marginLeft: -2,
    marginTop: top || -5
  })
}));

interface IProps {
  group: string;
  title: string;
  children: any;
  top?: number;
  className?: any;
  type?: 'text' | 'node';
  description?: string;
}

const WrapedComponent = (props: any) => <div dangerouslySetInnerHTML={{__html: props.str}}></div>

export default function Descriptor({ group, title, children, top, description, className, type='text' }: IProps) {
  const classes = useStyles({ top });

  let tooltipTitle = (descriptions[group] && descriptions[group][title]) || description;
  if(type === 'node'){
    tooltipTitle = <WrapedComponent str={descriptions[group][title]} />
  }

  return (
    <div className={clsx(classes.root, className)}>
      {children}
      {((descriptions[group] && descriptions[group][title]) || description) && (
        <LightTooltip title={tooltipTitle} placement="top" >
          <InfoIcon className={classes.desc} />
        </LightTooltip>
      )}
    </div>
  );
}
