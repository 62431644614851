import { useEffect } from 'react';

import Loader from '../../../../components/Loader';
import ProviderSettingsChangeForm from './ProviderSettingsChangeForm';
import { IProviderSettingsChangeFormProps } from '../../../../types';
import { IProviderSettings, useProviderSettingsSchema } from '../../../../api/paymentGateway';

interface IProviderSettingsInfo
  extends Omit<IProviderSettingsChangeFormProps, 'provider' | 'schema'> { selectedProvider: IProviderSettings }

export default function ProviderSettingsInfo({ selectedProvider, ...props }: IProviderSettingsInfo) {

  const [{ data: providerSettingsSchema }, loadingSchema, loadSchema] = useProviderSettingsSchema();

  useEffect(()=> {
    if (selectedProvider?.id) {
      loadSchema(selectedProvider.provider);
    }
  },[selectedProvider?.id]);

  return (
    <>
      <Loader loading={loadingSchema} width={560} />

      {providerSettingsSchema?.schema?.properties && !loadingSchema &&
        <ProviderSettingsChangeForm 
          schema={providerSettingsSchema.schema}
          provider={selectedProvider}
          {... props}
        />}
    </>
  );
}