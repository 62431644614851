import { createTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';

const theme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: blue[200],
      dark: blue[500],
      light: blue['A200'],
    },
  },
  overrides: {
    MuiOutlinedInput: {
      root: {},
      input: {
        padding: '12px 8px',
      },
    },
    MuiInputLabel: {
      root: {},
      outlined: {
        transform: 'translate(7px, 12px) scale(1)',
      },
    },
    MuiTableCell: {
      root: {},
      sizeSmall: {
        padding: '4px 15px 4px 0',
      },
    },
    MuiFormHelperText: {
      root: {},
      contained: {
        position: 'absolute',
        top: 40,
        whiteSpace: 'nowrap',
        margin: '0 !important',
      },
    },
  },
  typography: {
    fontSize: 12,
  },
});

export default theme;
