import { useEffect } from 'react';

import { makeStyles, Typography } from '@material-ui/core';

import { 
  IProviderSettings,
  useProviderSettingsCreate,
  useProviderSettingsDelete,
  useProviderSettingsList,
  useProviderSettingsUpdate }
from '../../../../api/paymentGateway';
import { useKycCountries } from '../../../../api/kyc';
import ProviderSettingsInfo from './ProviderSettingsInfo';
import ProviderSettingsCreateForm from './ProviderSettingsCreateForm';
import DetailsPopup from '../../../../components/Layout/DetailsPopup';
import { useLegalEntityList } from '../../../../api/legalEntity';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2.5),
  },
}));

interface IProviderSettingsDetails {
  id: string;
  onClose: () => void;
  updateList: ({}) => void
}

export default function ProviderSettingsDetails({ id, updateList, onClose }: IProviderSettingsDetails) {
  const classes = useStyles();

  const [{ items }, loading, load ] = useProviderSettingsList();
  const [{ items: countries }, loadingCountries, loadCountries] = useKycCountries();
  const [{ items: entities }, loadingEntity, loadEntity] = useLegalEntityList();

  const selectedProvider = items.find((item: IProviderSettings) => item?.id === id);

  const updateProvidersAndCloseCallback = () => {
    updateList({});
    onClose();
  };
  
  useEffect(() => {
    load({});
    loadEntity();
    loadCountries();
  }, []);

  const [create, creating] = useProviderSettingsCreate((res: any) => {
    if (res.success) {
      updateProvidersAndCloseCallback();
    }
  });
  
  const [update, updating] = useProviderSettingsUpdate((res: any) => {
    if (res.success) {
      updateProvidersAndCloseCallback();
    }
  });

  const [deleteSettings, deleting] = useProviderSettingsDelete((res: any) => {
    if (res.success) {
      updateProvidersAndCloseCallback();
    }
  });

  return (
    <DetailsPopup onClose={onClose} loading={loading || creating || updating || loadingCountries || loadingEntity}>
      {id === 'new' && (
        <>
          <Typography variant="h6" className={classes.title} color="primary">Create provider settings</Typography>
          <ProviderSettingsCreateForm 
            create={create} 
            creating={creating}
            entities={entities}
            countries={countries}
            loadingCountries={loadingCountries} 
          />
        </>
      )}

      {selectedProvider?.id && (
        <>
          <Typography variant="h6" className={classes.title} color="primary">
            {`Update provider settings ${selectedProvider?.id} (${selectedProvider?.provider})`}
          </Typography>
          <ProviderSettingsInfo 
            update={update} 
            updating={updating}
            deleting={deleting}
            entities={entities} 
            countries={countries}
            deleteSettings={deleteSettings}
            selectedProvider={selectedProvider} 
            loadingCountries={loadingCountries} 
          />
        </>
      )}
    </DetailsPopup>
  );
}
