import { TableHead, TableRow, Table, TableBody, TableCell } from '@material-ui/core';

import clsx from 'clsx';

import { IColumn } from '../../../hooks/useColumns';
import DateView from '../../../components/DateView';
import useMobileStyles from '../../../hooks/useMobileStyles';
import Tablecell from '../../../components/TableCell/TableCell';
import { IProviderSettings } from '../../../api/paymentGateway';
import useGlobalStyles from '../../../components/Layout/useGlobalStyles';

interface IProviderSettingsList {
  items: IProviderSettings[];
  columns: IColumn[];
  onRowClick: (id?: string) => void;
}

const content: { [key: string]: (i: IProviderSettings, key: number) => void } = {
  id: (i, key) => <Tablecell title="ID" key={key}>{i.id}</Tablecell>,
  provider: (i, key) => <Tablecell title="Provider" key={key}>{i.provider}</Tablecell>,
  legalEntity: (i, key) => 
  <Tablecell title="Legal Entity" key={key}>
    {i?.legalEntity ? i.legalEntity : '-'}
  </Tablecell>,
  merchantId: (i, key) => <Tablecell title="Merchant Id" key={key}>{i?.merchantId ? i.merchantId : '-'}</Tablecell>,
  created: (i, key) => <Tablecell title="Created at" key={key}><DateView value={i.createdAt} /></Tablecell>,
  updated: (i, key) => <Tablecell title="Updated at" key={key}><DateView value={i.updatedAt} /></Tablecell>,
};

const ProviderSettingsList = ({ items, onRowClick, columns }: IProviderSettingsList) => {
  const classes = useGlobalStyles();
  const mobileClasses = useMobileStyles();

  return (
    <Table size="small">
      <TableHead>
        <TableRow className={mobileClasses.tableHead}>
          {columns.map((c) => c.component || <TableCell key={c.id}>{c.name}</TableCell>)}
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((i) => (
          <TableRow 
            key={i.id} 
            className={clsx(mobileClasses.row, classes.pointer, mobileClasses.lastChild)}
            onClick={() => onRowClick(i.id.toString())} 
            hover
          >
            {columns.map((c, index) => content[c.id](i, index))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default ProviderSettingsList;