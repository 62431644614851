import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Waypoint } from 'react-waypoint';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CardActions from '@material-ui/core/CardActions';
import OrderList from './OrdersList';
import OrdersFilter, { IOrderFilter } from './OrdersFilter';
import EmptyList from '../../components/EmptyList';
import Loader from '../../components/Loader';
import ColHead from '../../components/UI/ColHead';
import useGlobalStyles from '../../components/Layout/useGlobalStyles';
import { useOrdersList } from '../../api/order';
import useFilter from '../../hooks/useFilter';
import useColumns, { IColumn } from '../../hooks/useColumns';
import { clearDatesIfIdsInFilter } from '../../helpers/filtersHelper';
import FilterWrapper, { ICollapseHandle } from '../../components/FilterWrapper/FilterWrapper';
import { CLEAR_DATES_FOR_FIELDS } from '../../constants/common';
import { normalizeToArray } from '../../helpers/normalizeToArray';

const defaultColumns: IColumn[] = [
	{ id: 'id', name: 'ID', active: true, component: <ColHead title="ID" key="id" /> },
	{ id: 'extId', name: 'Ext. ID', active: true, component: <ColHead title="Ext. ID" key="extId" /> },
	{ id: 'invoiceId', name: 'Invoice ID', active: true, component: <ColHead title="Invoice ID" key="invoiceId" /> },
	{ id: 'type', name: 'Type', active: true, component: <ColHead title="Type" key="type" /> },
	{ id: 'requested', name: 'Requested by', active: true, component: <ColHead title="Requested by" key="reqBy" /> },
	{ id: 'customer', name: 'Customer', active: true, component: <ColHead title="Customer" key="customer" /> },
	{ id: 'amount', name: 'Amount', active: true, component: <ColHead title="Amount" key="amount" /> },
	{
		id: 'paymentAmount',
		name: 'Payment amount',
		active: true,
		component: <ColHead title="Payment amount" key="pAmount" />
	},
	{
		id: 'paymentsCount',
		name: 'Payments count',
		active: true,
		component: <ColHead title="Payments count" key="pCount" />
	},
	{
		id: 'paymentMethod',
		name: 'Payment Method',
		active: true,
		component: <ColHead title="Payment Method" key="pMethod" />
	},
	{ id: 'sid', name: 'SID', active: true, component: <ColHead title="SID" key="sid" /> },
	{ id: 'status', name: 'Status', active: true, component: <ColHead title="Status" key="status" /> },
	{
		id: 'paymentFinished',
		name: 'Payment finished',
		active: true,
		component: <ColHead title="Payment finished" key="pFinished" />
	},
	{
		id: 'convRate', name: 'Conversion rate', active: true,
		component: <ColHead title="Conversion rate" key="cRate" />
	},
	{ id: 'convAt', name: 'Converted at', active: true, component: <ColHead title="Converted at" key="cAt" /> },
	{ id: 'created', name: 'Created at', active: true, component: <ColHead title="Created at" key="created" /> },
	{ id: 'expire', name: 'Will expire', active: true, component: <ColHead title="Will expire" key="expire" /> },
	{ id: 'legalEntity', name: 'Legal Entity', active: true, component: <ColHead title="Legal Entity" key="legalEntity" /> },
];

export default function OrdersPage() {
	const params = useLocation();
	const requestedById = new URLSearchParams(params.search).get('requestedById');
	const [isIgnoreDateFilter, setIsIgnoreDateFilter] = useState(false);

	const accordionRef = useRef<ICollapseHandle>(null);
	const classes = useGlobalStyles();
	const [{ items, cursor, full, error }, loading, load] = useOrdersList();

	const globalCountry = useSelector((store: any) => store.appData.countries.globalCountry);


	const emptyFilter = () => ({
		status: undefined,
		currency: undefined,
		paymentCurrency: undefined,
		externalId: undefined,
		orderId: undefined,
		requestedBy: undefined,
		acceptedBy: undefined,
		legalEntities: [],
		country: globalCountry || undefined,
		paymentMethod: undefined,
		orderType: undefined,
		sid: undefined,
		instantPaymentScheme: undefined
	});
	const [setFilter, filter] = useFilter('orders', emptyFilter());
	const [columns, ColumnsFilter] = useColumns('orders', defaultColumns);
	const history = useHistory();

	function setDetailsUrl(id?: string) {
		if (id) {
			history.push(`/orders/order/${id}`);
		} else {
			history.push('/orders');
		}
	}

	function checkIgnoringFilterDate(filter: Record<string, any>) {
		if (isIgnoreDateFilter) {
			return { ...filter, dateFrom: null, dateTo: null };
		}

		return filter;
	}


	useEffect(() => {
		setFilter({
			...filter,
			country: globalCountry,
		});
	}, [globalCountry]);

	useEffect(() => {
		const reqFilters = clearDatesIfIdsInFilter(filter, CLEAR_DATES_FOR_FIELDS);
		load({
			filter: checkIgnoringFilterDate(reqFilters),
			cursor: 0,
		});

	}, [filter]);

	useEffect(() => {
		requestedById && setFilter({ requestedBy: requestedById });
	}, [requestedById]);

	const onChangeFilter = (filters: any) => {
		accordionRef.current?.collapse();
		const normalizedFilters = {
			...filters,
			legalEntities: normalizeToArray(filters.legalEntities),
			acceptedBy: typeof filters.acceptedBy === 'string' ? filters.acceptedBy.toLowerCase().trim() : undefined,
			requestedBy: typeof filters.requestedBy === 'string' ? filters.requestedBy.toLowerCase().trim() : undefined
		};
		setFilter(normalizedFilters);
	};

	return (
		<Grid container spacing={3}>
			<FilterWrapper ref={accordionRef}>
				<OrdersFilter
					setIsIgnoreDateFilter={setIsIgnoreDateFilter}
					isIgnoreDateFilter={isIgnoreDateFilter}
					filter={filter as IOrderFilter}
					onChange={onChangeFilter}
				/>
			</FilterWrapper>
			<Grid item className={classes.table}>
				<Paper className={classes.paper}>
					<CardActions>
						<Grid container justifyContent="flex-end">
							<Grid item>
								<ColumnsFilter />
							</Grid>
						</Grid>
					</CardActions>
					{items.length > 0
						? <OrderList items={items} onRowClick={setDetailsUrl} columns={columns} />
						: <EmptyList error={error} loading={loading} />}
					<Loader loading={loading} />
					{!loading && !error && !full &&
            <Waypoint onEnter={() =>
							load({
								cursor,
								filter: checkIgnoringFilterDate(clearDatesIfIdsInFilter((filter),
									CLEAR_DATES_FOR_FIELDS))
							})}
            />}
				</Paper>
			</Grid>
		</Grid>
	);
}
