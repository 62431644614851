import makeStyles from '@material-ui/core/styles/makeStyles';

const useFormStyles = makeStyles((theme) => ({
  root: {},
  row: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'flex-start',
    '& > *': {
      flex: '1 1 0px',
      marginRight: `${theme.spacing(2)}px !important`,
      '&:last-child': {
        marginRight: 0
      }
    },
    '&.closest > *': {
      marginRight: `4px !important`,
    },
    '& .v-center': {
      alignSelf: 'center'
    },
    '& .v-left': {
      alignSelf: 'flex-start'
    },
    '&.wrap': {
      'flexWrap': 'wrap',
      '& > *': {
        flex: '0 0 45%',
        marginTop: 4
      },
    },
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column',
    },
  },
  endBlock: {
    marginBottom: theme.spacing(2)
  },
  startBlock: {
    marginTop: theme.spacing(2)
  },
  actions: {
    marginTop: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between'
  },
  button: {
    flex: '0 0 auto'
  }
}));

export default useFormStyles;
