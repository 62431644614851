import React from 'react';
import { Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import { predefinedDates } from '../../helpers/datesHelper';
import { DateTimeField, Select } from '../Form';
import useGlobalStyles from '../Layout/useGlobalStyles';

interface IProps {
  children?: any[] | any;
  onApply(values: any): void;
  filter: any;
  useUpdateDateFilter?: boolean;
  onExport?(): void;
  className?: string;
}

export default function TableFilter({ children, onApply, filter, useUpdateDateFilter, onExport, className }: IProps) {
  const classes = useGlobalStyles();
  let dates: string | undefined;
  let updateDates: string | undefined;
  const datesOptions: any[] = [];
  const updateDatesOptions: any[] = [];
  const datesObj = predefinedDates();
  if (filter.pickerViewDateFrom !== undefined || filter.date1 !== undefined) {
    const dateFrom = filter.date1 || filter.pickerViewDateFrom;
    const dateTo = filter.date2 || filter.pickerViewDateTo;
    Object.entries(datesObj).forEach(([id, data]) => {
      datesOptions.push({ value: id, label: data.title });
      if (data.dateFrom === dateFrom && data.dateTo === dateTo) {
        dates = id;
      }
    })
  }

  if (useUpdateDateFilter && filter.updatePickerViewDateFrom !== undefined) {
    const dateFrom = filter.updatePickerViewDateFrom;
    const dateTo = filter.updatePickerViewDateTo;
    Object.entries(datesObj).forEach(([id, data]) => {
      updateDatesOptions.push({ value: id, label: data.title });
      if (data.dateFrom === dateFrom && data.dateTo === dateTo) {
        updateDates = id;
      }
    });
  }

  return (
    <Form
      onSubmit={onApply}
      initialValues={{ ...filter, dates, updateDates }}
      mutators={{
        setDates: ([date], state, { changeValue }) => {
          // @ts-ignore
          const d = datesObj[date];
          if (d) {
            changeValue(state, 'pickerViewDateFrom', () => d.dateFrom);
            changeValue(state, 'pickerViewDateTo', () => d.dateTo);
          } else if (datesObj?.default) {
            changeValue(state, 'pickerViewDateFrom', () => datesObj.default.dateFrom);
            changeValue(state, 'pickerViewDateTo', () => datesObj.default.dateTo);
          }
        },
        setUpdateDates: ([date], state, { changeValue }) => {
          // @ts-ignore
          const d = datesObj[date];
          if (d) {
            changeValue(state, 'updatePickerViewDateFrom', () => d.dateFrom);
            changeValue(state, 'updatePickerViewDateTo', () => d.dateTo);
          } else if (datesObj?.default) {
            changeValue(state, 'updatePickerViewDateFrom', () => datesObj.default.dateFrom);
            changeValue(state, 'updatePickerViewDateTo', () => datesObj.default.dateTo);
          }
        }
      }}
      render={({ values, handleSubmit, form }) =>{
       return (
        <form className={clsx(classes.filters, className)} onSubmit={handleSubmit}>
          {datesOptions.length > 0 && (
            <>
              <DateTimeField name="pickerViewDateFrom" label="Date from" max={values.pickerViewDateTo || undefined} />
              <DateTimeField name="pickerViewDateTo" label="Date to" min={values.pickerViewDateFrom || undefined} />
              <Select
                name="dates"
                label="Period"
                options={datesOptions}
              />
              <OnChange name="dates">
                {(v: string) => form.mutators.setDates(v)}
              </OnChange>
            </>
          )}
          {updateDatesOptions.length > 0 && (
            <>
              <DateTimeField name="updatePickerViewDateFrom" label="Update date from" max={values.updatePickerViewDateTo || undefined} />
              <DateTimeField name="updatePickerViewDateTo" label="Update date to" min={values.updatePickerViewDateFrom || undefined} />
              <Select
                name="updateDates"
                label="Update period"
                options={updateDatesOptions}
              />
              <OnChange name="updateDates">
                {(v: string) => form.mutators.setUpdateDates(v)}
              </OnChange>
            </>
          )}
          {children}
          <Button type="submit" variant="contained" size="large" className="apply">Apply</Button>
          {onExport && (
            <Button
              variant="contained"
              size="large"
              className="apply"
              onClick={onExport}
            >
              Export
            </Button>
          )}
        </form>
      )}}
    />
  )
}
