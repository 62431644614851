import { FieldArray } from 'react-final-form-arrays';

import clsx from 'clsx';

import { Button, makeStyles } from '@material-ui/core';

import { required } from '../../../../validators';
import { Input } from '../../../../components/Form';
import useFormStyles from '../../../../hooks/useFormStyles';


const useStyles = makeStyles((theme) => ({
  button: {
    width: '284px',
    [theme.breakpoints.down(700)]: {
      width: '100%',
    },
  },
  row: {
    flex: '0 0 300px',
    width: '300px',
    paddingTop: '10px',
    [theme.breakpoints.down(700)]: {
      flex: '0 0 100%',
      width: '100%',
    },
  },
 title: {
   marginBottom: theme.spacing(2.5),
 },
}));

interface IDynamicAccountsForm {
  fieldName: string
}

function DynamicAccountsForm({ fieldName }: IDynamicAccountsForm) {

  const classes = useStyles();
  const formClasses = useFormStyles();

  return (
    <FieldArray name={fieldName}>
      {({ fields }) => (
        <div>
          {fields.map((name, index) => (
            <div key={name}>
              <div className={formClasses.row}>
                <Input
                  name={`${name}.currency`}
                  label="Currency"
                  type="search"
                  autoComplete="xyz123"
                  validate={required}
                />
              </div>
              <div className={formClasses.row}>
                <Input
                  name={`${name}.id`}
                  label="ID"
                  type="search"
                  autoComplete="xyz123"
                  validate={required}
                />
              </div>
              <div className={clsx(formClasses.row, classes.row)}>
                <Button
                  type="button"
                  variant="contained"
                  className={classes.button}
                  color="secondary"
                  onClick={() => fields.remove(index)}
                >
                  Remove
                </Button>
              </div>
            </div>
          ))}
          <div className={clsx(formClasses.row, classes.row)}>
            <Button
              type="button"
              className={classes.button}
              variant="contained"
              onClick={() => fields.push({ currency: '', id: '' })}
            >
              Add
            </Button>
          </div>
        </div>
      )}
    </FieldArray>
  );
}

export default DynamicAccountsForm;