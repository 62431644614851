import ProviderSettingsForm from './ProviderSettingsForm';
import { IDynamicProviderSettingsCreateFormProps } from '../../../../types';
import { mapAccountsByCurrency } from '../../../../helpers/providerSettings';

const DynamicProviderSettingsCreateForm = ({ 
  schema, 
  create,
  provider,
  creating,
  ...props
  }: IDynamicProviderSettingsCreateFormProps) => {

  const handleSubmitForm = (values: Record<string, any>) => {
    const { legalEntity, '2fa': authCode, merchantIDMain, ...rest } = values;

    if (rest?.accounts) {
      rest.accounts = mapAccountsByCurrency(rest.accounts);
    }
    
    const payload = {
      provider,
      merchantId: merchantIDMain ?? '',
      '2fa': authCode,
      legalEntity: legalEntity ?? '',
      config: { ...rest, type: provider }
    };

    create(payload);
  };

  return (
    <ProviderSettingsForm
      schemaId={schema?.id}
      properties={schema.properties}
      onSubmitForm={handleSubmitForm}
      isDisabledSubmitButton={creating}
      { ...props}
    />
  );
};

export default DynamicProviderSettingsCreateForm;