import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import RouteWithLayout from './components/Route';
import KYCPage from './views/KYC';
import KYCLimitsPage from './views/KycLimits';
import OrdersPage from './views/Orders';
import CustomersPage from './views/Customers';
import WalletsPage from './views/Wallets';
import ClearingPage from './views/Clearing';
import BlockchainTxPage from './views/Blockchain';
import AdminsPage from './views/Admins';
import RollingPage from './views/Rolling';
import AdminSettings from './views/Admins/Settings';
import PaymentsPage from './views/Payments';
import CurrenciesPage from './views/Currencies';
import WalletOperationsPage from './views/WalletOperations';
import ApiKeysPage from './views/ApiKeys';
import KYCCountriesPage from './views/KycCountries';
import AdminsActivitiesPage from './views/Admins/ActivitiesPage';
import BookkeepingBalance from './views/Bookkeeping/Balance';
import BookkeepingReport from './views/Bookkeeping/Report';
import PaymentProvidersPage from './views/GlobalSettings/PaymentProviders';
import ApmPage from './views/APM';
import ACLPage from './views/ACL/BlakWhite';
import CardControllerPage from './views/ACL/CardController';
import BinCodesPage from './views/ACL/BinCodes';
import { GatewaysPage } from './views/GlobalSettings/GatewaysConfig';
import OlyInvoicePage from './views/Other/OlyInvoice';
import UpdateWebflow from './views/UpdateWebflow';
import LegalEntityPage from './views/LegalEntity';
import PaymentGroupPage from './views/GlobalSettings/PaymentGroups';
import PaymentMethodPage from './views/GlobalSettings/PaymentMethods';
import TracingPage from './views/Other/Tracing';
import ApiRequests from './views/Other/ApiRequests';
import SettlementsPage from './views/Settlements';
import OBIReportsPage from './views/OBIReports';
import ProviderSettingsPage from './views/PaymentGateway/ProviderSettings';

export default function Routes() {
  return (
    <Switch>
      <Redirect to="/orders" from="/" exact />
      <RouteWithLayout component={KYCPage} path="/kyc" title="KYC" />
      <RouteWithLayout
        component={KYCCountriesPage}
        path="/kyc-countries/:countryId?"
        title="KYC Countries"
      />
      <RouteWithLayout
        component={KYCLimitsPage}
        path="/settings/kyc-limits/:limitId?"
        title="KYC Limits"
      />
      <RouteWithLayout component={OrdersPage} path="/orders" title="Orders" />
      <RouteWithLayout
        component={ClearingPage}
        path="/clearing"
        title="Clearing"
      />
      <RouteWithLayout
        component={BlockchainTxPage}
        path="/blockchain/:blockchainId?"
        title="Blockchain TXs"
      />
      <RouteWithLayout
        component={CustomersPage}
        path="/customers"
        title="Customers"
      />
      <RouteWithLayout
        component={WalletsPage}
        path="/wallets"
        title="Wallets"
      />
      <RouteWithLayout
        component={RollingPage}
        path="/rolling"
        title="Rolling reserve"
      />
      <RouteWithLayout
        component={AdminsPage}
        path="/admins/list/:adminId?"
        title="Admins"
      />
      <RouteWithLayout
        component={AdminsActivitiesPage}
        path="/admins/activities"
        title="Admins activities"
      />
      {/*<RouteWithLayout*/}
      {/*  component={AuthoritiesPage}*/}
      {/*  path="/admins/authorities/:authorityId?"*/}
      {/*  title="Authorities"*/}
      {/*/>*/}
      <RouteWithLayout
        component={AdminSettings}
        path="/profile/settings"
        title="Profile settings"
      />
      <RouteWithLayout
        component={PaymentsPage}
        path="/payments"
        title="Payments"
      />
      <RouteWithLayout
        component={WalletOperationsPage}
        path="/wallet-operations"
        title="Wallet operations"
      />
      <RouteWithLayout
        component={CurrenciesPage}
        path="/currencies/:currency?"
        title="Currencies"
      />
      <RouteWithLayout
        component={ApiKeysPage}
        path="/widget-keys"
        title="API keys"
      />
      <RouteWithLayout
        component={BookkeepingBalance}
        path="/bookkeeping/balance/:itemId?"
        title="Bookkeeping operations"
      />
      <RouteWithLayout
        component={BookkeepingReport}
        path="/bookkeeping/report"
        title="Bookkeeping balance report"
      />
      <RouteWithLayout
        component={PaymentProvidersPage}
        path="/payment-providers"
        title="Payment providers"
      />
      <RouteWithLayout
        component={ApmPage}
        path="/settings/apm/:apmId?"
        title="APM"
      />
      <RouteWithLayout
        component={PaymentGroupPage}
        path="/settings/payment-groups/:groupId?"
        title="Payment groups"
      />
        <RouteWithLayout
          component={PaymentMethodPage}
          path="/settings/payment-methods/:methodId?"
          title="Payment methods"
        />
      <Redirect to="/acl/black" from="/acl" exact />
      <RouteWithLayout
        component={ACLPage}
        path="/acl/black/(new)?"
        title="Blacklist"
      />
      <RouteWithLayout
        component={ACLPage}
        path="/acl/white/(new)?"
        title="Whitelist"
      />
      <RouteWithLayout
        component={BinCodesPage}
        path="/acl/bin-codes"
        title="BIN codes"
      />
      <RouteWithLayout
        component={CardControllerPage}
        path="/acl/cards"
        title="Cards controller"
      />
      <RouteWithLayout
        component={GatewaysPage}
        path="/settings/gateway-configs/:gatewayId?"
        title="Gateways Configs"
      />
      <RouteWithLayout
        component={OlyInvoicePage}
        path="/other/oly/:olyInvoiceId?"
        title="Oly invoices"
      />
     <RouteWithLayout
        component={ApiRequests}
        path="/other/api-requests/:apiRequestId?"
        title="Api requests"
      />
     <RouteWithLayout
        component={TracingPage}
        path="/other/tracing"
        title="Tracing"
      /> 
      <RouteWithLayout
        component={UpdateWebflow}
        path="/webflow"
        title="Update Webflow"
      />
      <RouteWithLayout
        component={LegalEntityPage}
        path="/legal-entity/:entityId?"
        title="Legal Entity"
      />
        <RouteWithLayout
        component={SettlementsPage}
        path="/settlements/:settlementId?"
        title="Settlements"
      />
      <RouteWithLayout
        component={OBIReportsPage}
        path="/settings/obi-reports/:reportId?"
        title="Business name"
      />
      <RouteWithLayout
        component={ProviderSettingsPage}
        path="/payment-gateway/provider-settings/:providerId?"
        title="Provider Settings"
      />

      <Redirect to="/orders" />
    </Switch>
  );
}
