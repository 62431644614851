import React from 'react';

import { Form } from 'react-final-form';

import Button from '@material-ui/core/Button';
import { DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';

import { Input, ProtectionInput } from '../../../components/Form';
import { composeValidators, mustBeNumber, required } from '../../../validators';
import { ISettlement, SettlementStatus } from '../../../api/settlements';
import useFormStyles from '../../../hooks/useFormStyles';
import SelectField from '../../../components/Form/SelectField';
import { CurrencyType, ICurrency } from '../../../api/currency';

const useStyles = makeStyles((theme) => ({
   root: {
     marginTop: theme.spacing(2)
   },
   form: {
     padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
     backgroundColor: '#424242',
     borderRadius: '6px',
     maxWidth: 400
   },
   modal: {
     display: 'flex',
     zIndex: 10,
     justifyContent: 'center',
     alignItems: 'center',
     position: 'fixed',
     top: 0,
     left: 0,
     right: 0,
     bottom: 0,
     width: '100%',
     height: '100%',
     backgroundColor: 'rgba(0, 0, 0, 0.5)',
   },
   content: {
     paddingLeft: 0,
     paddingRight: 0,
   },
   title: {
     padding: `${theme.spacing(2)}px ${theme.spacing(0)}px`,
   },
 }));

 const STATUSES = [
   { value:SettlementStatus.REJECTED, label: SettlementStatus.REJECTED },
   { value:SettlementStatus.SETTLED, label: SettlementStatus.SETTLED }
 ];
 
interface ISettlementDialog {
   setOpenDialog: (open: boolean)=> void
   update: (data: any)=> void
   settlement: ISettlement
   currencies: ICurrency[]
}

function SettlementDialog({ setOpenDialog, update, settlement, currencies }:ISettlementDialog) {

  const classes = useStyles();
  const formClasses = useFormStyles();


  return(
    <div
      className={classes.modal}
      aria-labelledby="alert-modal-title"
      aria-describedby="alert-modal-description">
      <div className={classes.form}>
        <Form
          onSubmit={(values) => {
            if (values.status === SettlementStatus.SETTLED) {
              update({ 
                settlementId: settlement.id,
                status: values.status, 
                settlementCurrency: values.currency,
                settlementAmount: values.amount,
                blockchainTxId: values.blockchainTxId,
                '2fa': values['2fa']
              });
            } else {
              update({ 
                settlementId: settlement.id,
                status: values.status, 
                '2fa': values['2fa']
                });
            }
          }}
          initialValues={{ 
            '2fa': '', 
            currency: 'EUR', 
            status: SettlementStatus.REJECTED, 
            amount: '', 
            blockchainTxId: '' 
          }}
          render={({ handleSubmit, values, invalid, form }) => {
            const selectedCurrency = currencies.find((item: ICurrency) => item?.currency === values.currency);
  
            if (selectedCurrency?.type !== CurrencyType.CRYPTO) {
              form.change('blockchainTxId', '');
            }

            return (
              <form onSubmit={handleSubmit}>
                <DialogContent className={classes.content}>
                  <DialogTitle className={classes.title}>
                    Confirmation of settlement for merchant {settlement.customerId}
                  </DialogTitle>
                  <div className={formClasses.row}>
                    <SelectField 
                      fullWidth 
                      name="status" 
                      noEmptyOption
                      label="Status" 
                      options={STATUSES} 
                    /> 
                  </div>
                  {values.status === SettlementStatus.SETTLED && 
                    <>
                      <div className={formClasses.row}>
                        <Input
                          name="amount"
                          label="Amount"
                          validate={composeValidators(required, mustBeNumber)}
                          fullWidth
                        />
                      </div>
                      <div className={formClasses.row}>
                        <SelectField 
                          fullWidth 
                          name="currency" 
                          noEmptyOption
                          label="Currency" 
                          options={currencies.map((item: ICurrency)=>({ label: item.currency, value: item.currency }))} 
                        /> 
                      </div>
                      {selectedCurrency?.type === CurrencyType.CRYPTO &&
                        <div className={formClasses.row}>
                          <Input
                            name="blockchainTxId"
                            label="Tx hash "
                            fullWidth
                          />
                        </div>}
                    </>}
                  <div className={formClasses.row}>
                    <ProtectionInput />
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setOpenDialog(false)} size="small">
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    color="secondary"
                    disabled={invalid}
                  >
                    Confirm
                  </Button>
                </DialogActions>
              </form>
            );
          }}
        />
      </div>
    </div>
  );
}

export default SettlementDialog;