import React, { useState } from 'react';

import clsx from 'clsx';

import { NavLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import DateView from '../../components/DateView';
import BoolLabel from '../../components/Label/BoolLabel';
import ShortString from '../../components/UI/ShortString';
import OrderStatusLabel from '../../components/Label/OrderStatusLabel';
import OrderTypeLabel from '../../components/Label/OrderTypeLabel';
import PopupLink from '../../components/UI/PopupLink';
import { IOrderItem } from '../../api/order';
import { getAcceptedBy } from '../../helpers/customerHelper';
import { IColumn } from '../../hooks/useColumns';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TableCellId from '../../components/TableCellId/TableCellId';
import Tablecell from '../../components/TableCell/TableCell';
import { useBreakpoints } from '../../hooks/useBreakpoints ';
import useMobileStyles from '../../hooks/useMobileStyles';

const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    cursor: 'pointer',
  },
}));

interface IOrderListProps {
  items: IOrderItem[];
  columns: IColumn[];
  onRowClick: any;
}

function RequestedComponent({ i, k }: any) {
  const urlFilter = i.requestedById ? `/orders?requestedById=${i.requestedById}` : '';
  const urlDetails = i.requestedById ? `/orders/customer/${i.requestedById}` : '';

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Tablecell key={k} title="Requested">
      <Link onClick={(e: any) => { e.stopPropagation(); handleClick(e); }}>
        {i.requestedByEmail || i.requestedById}
      </Link>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={(e: any) => { e.stopPropagation(); handleClose(); }}
        style={{ marginTop: 5, marginLeft: 10 }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={(e: any) => { e.stopPropagation(); handleClose(); }}>
          <Link component={NavLink} to={`${urlFilter}`} onClick={(e: any) => { e.stopPropagation(); handleClose(); }}>Filter</Link>
        </MenuItem>
        <MenuItem onClick={(e: any) => { e.stopPropagation(); handleClose(); }}>
          <Link component={NavLink} to={`${urlDetails}`} onClick={(e: any) => { e.stopPropagation(); handleClose(); }}>Show Details</Link>
        </MenuItem>
      </Menu>
    </Tablecell>
  );
}

export default function OrderList({ items, onRowClick, columns }: IOrderListProps) {
  const classes = useStyles();
  const mobileClasses = useMobileStyles();

  const isSm = useBreakpoints('sm');

  const content: { [key: string]: (item: IOrderItem, key: number) => void } = {
    id: (i, k) => <TableCellId key={k}>{i.id}</TableCellId>,
    extId: (i, k) => <Tablecell title="External ID" key={k}>
      <ShortString chars={10} text={i.externalId} />
    </Tablecell>,
    invoiceId: (i, k) => <Tablecell title="Invoice ID" key={k}>
      <ShortString chars={10} text={i.invoiceId} />
    </Tablecell>,
    type: (i, k) => <Tablecell title="Type" key={k}><OrderTypeLabel type={i.type} /></Tablecell>,
    requested: (i, k) => <RequestedComponent key={k} k={k} i={i} />,
    customer: (i, k) => (
      <Tablecell key={k} title="Customer">
        <PopupLink popup="customer" id={i.acceptedById}>{getAcceptedBy(i)}</PopupLink>
      </Tablecell>
    ),
    amount: (i, k) => <Tablecell key={k} title="Amount" isNowrap>{i.amount} {i.currency}</Tablecell>,
    paymentAmount: (i, k) => <Tablecell key={k} title="Payment amount" isNowrap>
      {i.paymentAmount} {i.paymentCurrency}
    </Tablecell>,
    paymentsCount: (i, k) => <Tablecell key={k} title="Payments count" position={isSm ? 'left' : 'center'}>
      {i.paymentsCount}
    </Tablecell>,
    paymentMethod: (i, k) => <Tablecell key={k} title="Payment Method" isNowrap>{i.apmData?.lastId}</Tablecell>,
    sid: (i, k) => <Tablecell key={k} title="SID" isNowrap>{i.sid}</Tablecell>,
    status: (i, k) => <Tablecell key={k} title="Status"><OrderStatusLabel status={i.status} /></Tablecell>,
    paymentFinished: (i, k) => <Tablecell title="Payment finished" key={k}>
      <BoolLabel value={i.paymentsFinished} size="small" />
    </Tablecell>,
    convRate: (i, k) => <Tablecell key={k} title="Conversion rate">{i.conversionRate}</Tablecell>,
    convAt: (i, k) => <Tablecell key={k} title="Converted at"><DateView value={i.convertedAt} /></Tablecell>,
    created: (i, k) => <Tablecell key={k} title="Created at">
      <DateView value={i.createdAt} />
    </Tablecell>,
    expire: (i, k) => <Tablecell key={k} title="Will expire">
      {i.expireAt ? <DateView value={i.expireAt} /> : '-'}
    </Tablecell>,
    legalEntity: (i, k) => <Tablecell key={k} title="LE">
      {i.legalEntity ? i.legalEntity  : '-'}
    </Tablecell>
  };

  return (
    <Table size="small">
      <TableHead>
        <TableRow className={mobileClasses.tableHead}>
          {columns.map((c) => c.component || <TableCell key={c.id}>{c.name}</TableCell>)}
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((i) => (
          <TableRow key={i.id}
            className={clsx(classes.row, mobileClasses.row, mobileClasses.lastChild)}
            onClick={() => onRowClick(i.id)}
            hover
          >
            {columns.map((c, index) => content[c.id](i, index))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
