import React, { useState, useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import BoolLabel from '../../../components/Label/BoolLabel';
import { IPaymentGroup } from '../../../api/paymentGroup';
import useGlobalStyles from '../../../components/Layout/useGlobalStyles';
import { IColumn } from '../../../hooks/useColumns';
import { useListPaymentMethod } from '../../../api/paymentMethod';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import PaymentMethodDetails from '../PaymentMethods/Details';
import useMobileStyles from '../../../hooks/useMobileStyles';
import Tablecell from '../../../components/TableCell/TableCell';

interface IProps {
  item: IPaymentGroup;
  columns: IColumn[];
  openDetails: any;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  highlight: {
    background: 'rgba(256,256,256,0.1)',
    '&:first-of-type': {
      paddingLeft: theme.spacing(1)
    }
  },
  request: {
    wordBreak: 'break-all'
  },
  logo:{
    maxHeight: '48px'
  },
  buttonCell: {
    textAlign: 'end'
  },
  addButton: {
    margin: theme.spacing(1)
  }
}));

export default function PaymentGroupRow({ item, columns, openDetails }: IProps) {
  const [expand, setExpand] = useState(false);
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const mobileClasses = useMobileStyles();
  const [methodId, setMethodId] = useState<any>();
  const [{ items, error }, loading, load ] = useListPaymentMethod();

  useEffect(() => {
    if (expand) {
      load({ filter: { group: item.id } });
    }
  }, [expand]);

  function openMethod(id: any){
    setMethodId(id);
  }

  const content: { [key: string]: (item: IPaymentGroup, key: number) => void } = {
    id: (i, k) =>
      <TableCell key={k} className={clsx({ [classes.highlight]: expand })}>
        <Link onClick={(e: any) => { e.stopPropagation(); openDetails(item.id); }}>
          {item.id}
        </Link>
      </TableCell>,
    name: (i, k) => (
      <TableCell key={k} className={clsx({ [classes.highlight]: expand })}>{item.name}</TableCell>
    ),
    logo: (i, k) =>
      <TableCell key={k} className={clsx({ [classes.highlight]: expand })}>
        <div className={classes.logo}>
          <img width={48} height="auto" src={item.logo}></img>
        </div>
      </TableCell>
  };


  return (
    <>
      <TableRow hover onClick={() => setExpand(!expand)} className={globalClasses.pointer}>
        {columns.map((c, index) => content[c.id](item, index))}
      </TableRow>
      {expand && (
        <TableRow>
          <TableCell colSpan={columns.length} className={classes.highlight}>
            <Table size="small">
              <TableHead className={mobileClasses.tableHead}>
                <TableRow>
                  <TableCell>Method ID</TableCell>
                  <TableCell>Method name</TableCell>
                  <TableCell>Enabled</TableCell>
                  <TableCell>Priority</TableCell>
                  <TableCell>Provider</TableCell>
                  <TableCell>Ext. ID</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.sort((a: any, b: any) => b.priority - a.priority).map((o: any, i: number) => (
                  <TableRow key={i} className={mobileClasses.row} hover onClick={() => openMethod(o.id)}>
                    <Tablecell title="Method ID">{o.id}</Tablecell>
                    <Tablecell title="Method name">{o.name}</Tablecell>
                    <Tablecell title="Enabled"><BoolLabel value={o.enabled} /></Tablecell>
                    <Tablecell title="Priority">{o.priority}</Tablecell>
                    <Tablecell title="Provider">{o.provider}</Tablecell>
                    <Tablecell title="Ext. ID">{o.externalId}</Tablecell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={6} className={classes.buttonCell}>
                    <Button
                      type="submit"
                      size="small"
                      variant="contained"
                      className={classes.addButton}
                      onClick={() => openMethod('new')}
                    >
                      Add payment method
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableCell>
        </TableRow>
      )}
      {(methodId) &&
          <PaymentMethodDetails
              id={methodId}
              group={item.id}
              onClose={() => setMethodId(null)}
              onSave={(id?: any) => {load({ filter: { group: item.id } }); setMethodId(id);}}
          />
      }
    </>

  );
}
