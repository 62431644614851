import React from 'react';

import clsx from 'clsx';

import { TableHead, TableRow, Table, TableBody, TableCell } from '@material-ui/core';

import { IOBIReport } from '../../api/reports';
import DateView from '../../components/DateView';
import { IColumn } from '../../hooks/useColumns';
import useMobileStyles from '../../hooks/useMobileStyles';
import Tablecell from '../../components/TableCell/TableCell';
import useGlobalStyles from '../../components/Layout/useGlobalStyles';

interface IOBIReportsList {
  items: IOBIReport[];
  columns: IColumn[];
  onRowClick: (id?: string) => void;
}
const content: { [key: string]: (i: IOBIReport, key: number) => void } = {
  id: (i, key) => <Tablecell title="ID" key={key}>{i.id}</Tablecell>,
  businessName: (i, key) => <Tablecell title="Business Name" key={key}>{i.businessName}</Tablecell>,
  email: (i, key) => <Tablecell title="Email" key={key}>{i.email}</Tablecell>,
  executedOrdersCount: (i, key) => 
    <Tablecell title="Executed orders count" key={key}>
      {i.executedOrdersCount}
    </Tablecell>,
  executedOrdersSum: (i, key) => <Tablecell title="Executed orders amount" key={key}>{i.executedOrdersSum}</Tablecell>,
  created: (i, key) => <Tablecell title="Created at" key={key}><DateView value={i.createdAt} /></Tablecell>,
  updated: (i, key) => <Tablecell title="Updated at" key={key}><DateView value={i.updatedAt} /></Tablecell>,
};

const OBIReportsList = ({ items, onRowClick, columns }: IOBIReportsList) => {
  const classes = useGlobalStyles();
  const mobileClasses = useMobileStyles();

  return (
    <Table size="small">
      <TableHead>
        <TableRow className={mobileClasses.tableHead}>
          {columns.map((c) => c.component || <TableCell key={c.id}>{c.name}</TableCell>)}
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((i) => (
          <TableRow 
            key={i.id} 
            className={clsx(mobileClasses.row, classes.pointer, mobileClasses.lastChild)}
            onClick={() => onRowClick(i.id.toString())} 
            hover
          >
            {columns.map((c, index) => content[c.id](i, index))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default OBIReportsList;