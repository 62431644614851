import { useEffect } from 'react';

import { useHistory, useParams } from 'react-router';

import { Button, CardActions, Grid, Paper } from '@material-ui/core';

import ProviderSettingsDetails from './Details';
import Loader from '../../../components/Loader';
import EmptyList from '../../../components/EmptyList';
import ProviderSettingsList from './ProviderSettingsList';
import useColumns, { IColumn } from '../../../hooks/useColumns';
import useGlobalStyles from '../../../components/Layout/useGlobalStyles';
import { useProviderSettingsList } from '../../../api/paymentGateway';

type IParams = {
  providerId: string
}

const defaultColumns: IColumn[] = [
  { id: 'id', name: 'ID', active: true },
  { id: 'provider', name: 'Provider', active: true },
  { id: 'legalEntity', name: 'Legal Entity', active: true },
  { id: 'merchantId', name: 'Merchant ID', active: true },
  { id: 'created', name: 'Created at', active: true },
  { id: 'updated', name: 'Updated at', active: true },
];

const ProviderSettingsPage = () => {
  const { providerId } = useParams<IParams>();
  const history = useHistory();

  const classes = useGlobalStyles();

  const [{ items, error }, loading, load ] = useProviderSettingsList();

  useEffect(() => {
    load({});
  }, []);

  const [columns, ColumnsFilter] = useColumns('providerSettings', defaultColumns);

  function setDetails(id?: string) {
    history.push(`/payment-gateway/provider-settings${id ? `/${id}` : ''}`);
  }
 
  return (
    <Grid container spacing={3}>
      <Grid item className={classes.table} style={{ width: '100%', minWidth: 'fit-content' }}>
        <Paper className={classes.paper}>
          <CardActions>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Button type="button" variant="contained" size="large" onClick={() => setDetails('new')}>Create</Button>
              </Grid>
              <Grid item>
                <ColumnsFilter />
              </Grid>
            </Grid>
          </CardActions>
          {items.length > 0
            ? <ProviderSettingsList items={items} onRowClick={setDetails} columns={columns} />
            : <EmptyList error={error} loading={loading} />
          }
          <Loader loading={loading} />
        </Paper>
      </Grid>
      {providerId && 
        <ProviderSettingsDetails 
          id={providerId} 
          updateList={load} 
          onClose={() => history.push('/payment-gateway/provider-settings')} 
        />}
    </Grid>
  );
};

export default ProviderSettingsPage;
