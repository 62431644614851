import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import EmptyList from '../../../components/EmptyList';
import Loader from '../../../components/Loader';
import useGlobalStyles from '../../../components/Layout/useGlobalStyles';
import useColumns, { IColumn } from '../../../hooks/useColumns';
import { IPaymentGroup, usePaymentGroupList } from '../../../api/paymentGroup';
import PaymentGroupList from './PaymentGroupList';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import PaymentGroupDetails from './Details';

const defaultColumns: IColumn[] = [
  { id: 'id', active: true, name: 'Group ID' },
  { id: 'name', active: true, name: 'Group name' },
  { id: 'logo', active: true, name: 'Logo' },
];

export default function PaymentGroupPage() {
  const classes = useGlobalStyles();
  const [{ items, error }, loading, load ] = usePaymentGroupList();
  const [columns, setColumns] = useColumns('payment-groups', defaultColumns);
  const history = useHistory();
  const { groupId }: any = useParams();
  const [group, setGroup] = useState<any>();

  function setDetails(id?: string) {
    if(id) {
      history.push(`/settings/payment-groups/${id}`);
      setGroupId(id);
    } else{
      history.push('/settings/payment-groups');
      setGroup(null);
    }
  }

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    if(groupId){
      setGroupId(groupId);
    }
  }, [items]);

  function setGroupId(id: string){
    setGroup(id === 'new' ? { id } : items.find((data: IPaymentGroup) => data.id === id));
  }

  function onUpdate() {
    load();
  }

  return (
    <Grid container spacing={3}>
      <Grid item className={classes.table} style={{ width: '100%', minWidth: 'fit-content' }}>
        <Paper className={classes.paper}>
          <CardActions>
            <Grid container justify="space-between">
              <Grid item>
                <Button type="button" variant="contained" size="large" onClick={() => setDetails('new')}>Create</Button>
              </Grid>
            </Grid>
          </CardActions>
          <Loader loading={loading}>
            {items.length > 0
              ? <PaymentGroupList items={items} columns={columns} openDetails={setDetails} />
              : <EmptyList error={error} loading={loading} />
            }
          </Loader>
        </Paper>
      </Grid>
      {(group?.id) &&
        <PaymentGroupDetails
          group={group}
          onClose={() => setDetails()}
          onSave={() => onUpdate()}
        />
      }
    </Grid>
  );
}
