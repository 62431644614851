import React, { useEffect, useState } from 'react';

import { Form } from 'react-final-form';

import { DataRow } from '../../../components/DataView';
import DateView from '../../../components/DateView';
import Loader from '../../../components/Loader';
import BlockchainLink from '../../../components/BlockchainLink';
import EmptyList from '../../../components/EmptyList';
import BlockchainTxStatusLabel from '../../../components/Label/BlockchainTxStatusLabel';
import { BlockchainTxStatus, IBlockchainTx, useBlockchainReinitTx, useBlockchainTxByOrder } from '../../../api/blockchain';
import { splitAddress } from '../../../helpers/addressHelper';
import ShortString from '../../../components/UI/ShortString';
import { Button, DialogActions, DialogContent, DialogContentText, makeStyles } from '@material-ui/core';
import { ProtectionInput } from '../../../components/Form';
import { Can } from '../../../acl/can';

const useStyles = makeStyles((theme) => ({
  root: {},
  sendConfirmation: {
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
    backgroundColor: '#424242',
    borderRadius: '6px',
    maxWidth: 600
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  mobile: {
    [theme.breakpoints.down('xs')]: {
      overflowWrap: 'anywhere',
    },
  }
}));

interface IProps {
  orderId: number;
}

export default function BlockchainTxTab({ orderId }: IProps) {
  const [{ items, error }, loading, load] = useBlockchainTxByOrder();
  const [openDialog, setOpenDialog] = useState(false);
  const classes = useStyles();

  const [reinitTx, sendingReinitTx] = useBlockchainReinitTx((res: any) => {
    if (res.success) {
      setOpenDialog(false);
      if (orderId) load(orderId);
    }
  });

  useEffect(() => {
    if (orderId) {
      load(orderId);
    }
  }, [orderId]);
  const tx = items[0] as IBlockchainTx;

  const [txAddress, txMemoTag] = splitAddress(tx?.address);

  if (!tx) {
    return (
      <Loader loading={loading} width={560}>
        <EmptyList loading={loading} error={error} text="No data to display" width={560} />
      </Loader>
    );
  }


  return (
    <Loader loading={loading} width={560}>
      <DataRow label="Blockchain TX ID">{tx.id}</DataRow>
      <DataRow label="Status">
        <BlockchainTxStatusLabel status={tx.status} />
      </DataRow>
      <Can I="manage" a="blockchain">
        {tx.status === 'ERROR' && (
            <DataRow label="Resubmit">
              <Button
                variant="contained"
                size="small"
                onClick={() => setOpenDialog(true)}
              >
                Resubmit
              </Button>
            </DataRow>
        )}
      </Can>
      <DataRow label="Amount">{tx.amount} {tx.currency}</DataRow>
      <DataRow label="Fee">{tx.fee} {tx.currency}</DataRow>
      <DataRow label="Provider">{tx.provider}</DataRow>
      <DataRow label="Address" copy={txAddress}>
        <BlockchainLink type="address" value={txAddress} currency={tx.currency} />
      </DataRow>
      {txMemoTag && (
        <DataRow label="Memo/Tag" copy={txMemoTag}>
          {txMemoTag}
        </DataRow>
      )}
      <DataRow label="TX ID" copy={tx.txId}>
        <BlockchainLink charsLimit={50} value={tx.txId} type="tx" provider={tx.provider} currency={tx.currency} />
      </DataRow>
      {tx.status === BlockchainTxStatus.ERROR && (
        <DataRow label="Error">
          <div className={classes.mobile}>
            <ShortString chars={300} text={tx.error} />
          </div>
        </DataRow>
      )}
      <DataRow label="Created">
        <DateView value={tx.createdAt} />
      </DataRow>
      <DataRow label="Updated">
        <DateView value={tx.updatedAt} />
      </DataRow>
      <>
        {openDialog &&
          <div
            className={classes.modal}
            aria-labelledby="alert-modal-title"
            aria-describedby="alert-modal-description">
              <div className={classes.sendConfirmation}>
                <Form
                  onSubmit={(values) => {
                    reinitTx({ ...values, transactionId: tx.id });
                  }}
                  initialValues={{ '2fa': '' }}
                  render={({ handleSubmit, values, invalid }) => (
                    <form onSubmit={handleSubmit}>
                      <DialogContent>
                        <DialogContentText id="alert-modal-description">
                          Are you sure that you want to reinitiate blockchain tx
                          with ID: {tx.id}
                          <ProtectionInput />
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => setOpenDialog(false)} size="small">
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          variant="contained"
                          size="small"
                          color="secondary"
                          disabled={invalid}
                        >
                          Confirm
                        </Button>
                      </DialogActions>
                    </form>
                  )}
                />
              </div>
          </div>}
      </>
    </Loader >

  );
}
