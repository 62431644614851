import Api, { useFetchApi, useUpdateApi } from './index';

import { IPayment } from './payment';
import { BlockchainTxProvider } from './blockchain';

export enum OrderStatus {
  NEW = 'NEW',
  ACCEPTED = 'ACCEPTED',
  PROCESSING = 'PROCESSING',
  VERIFICATION = 'VERIFICATION',
  SUSPICIOUS = 'SUSPICIOUS',
  EXECUTED = 'EXECUTED',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  EXPIRED = 'EXPIRED',
}

export enum OrderPostbackStatus {
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS'
}

export enum OrderType {
  FIAT_TO_CRYPTO = 'FIAT_TO_CRYPTO',
  FIAT_TO_WALLET = 'FIAT_TO_CRYPTO',
  CRYPTO_TO_WALLET = 'FIAT_TO_CRYPTO',
  WALLET_TO_CRYPTO = 'WALLET_TO_CRYPTO',
  WALLET_TO_FIAT = 'WALLET_TO_FIAT'
}

interface IAPMData {
  id: null | string
  lastId: null | string
  inputs: null | string
}

export interface IOrderItem {
  id: number;
  externalId: string;
  amount: string;
  sendAmount?: string;
  currency: string;
  address: string;
  paymentCurrency: string;
  paymentAmount: string;
  status: OrderStatus;
  acceptedById: number;
  acceptedByEmail?: string;
  acceptedByPhone?: string;
  url: string;
  blockchainTxId?: string;
  provider?: BlockchainTxProvider;
  payments?: IPayment[];
  paymentsCount?: number;
  paymentsFinished?: boolean;
  postbackUrl?: string;
  apmData?: IAPMData;
  postbackStatus?: OrderPostbackStatus;
  postbackSentAt?: Date;
  convertedAt: Date;
  requestedById: number;
  requestedByEmail?: string;
  error?: string;
  type?: OrderType;
  conversionRate?: number;
  createdAt: number;
  updatedAt: number;
  expireAt: number;
  successUrl?: string;
  failUrl?: string;
  fee?: IOrderFee;
  unDomain?: string;
  invoiceId?: string;
  legalEntity?: string;
  paymentGroup?: string;
  sid: string;
  instantPaymentScheme?: boolean
}

export interface IOrderInitDebug {
  id: number;
  createdAt: number;
  updatedAt: number;
  requestedById: number;
  url: string;
  headers: any;
  request: any;
  response: any;
}

export interface IOrderFee {
  deposit: number;
  depositFixed: number;
  rolling: number;
  withdrawal: number;
  withdrawalFixed: number;
}

export function useOrdersList() {
  return useFetchApi(
    async (query: any) => {
      const q: any = { filter: {} };
      Object.keys(query).forEach((k) => {
        if (k === 'filter') {
          Object.keys(query.filter).forEach((f) => {
            if (f === 'acceptedBy' && query.filter.acceptedBy) {
              if (/^\d+$/.test(query.filter.acceptedBy) && query.filter.acceptedBy.length <= 19) {
                q.filter.acceptedById = query.filter.acceptedBy;
              } else {
                q.filter.acceptedBy = query.filter.acceptedBy;
              }
            } else if (f === 'requestedBy' && query.filter.requestedBy) {
              if (/^\d+$/.test(query.filter.requestedBy) && query.filter.requestedBy.length <= 19) {
                q.filter.requestedById = query.filter.requestedBy;
              } else {
                q.filter.requestedBy = query.filter.requestedBy;
              }
            } else {
              q.filter[f] = query.filter[f];
            }
          });
        } else {
          q[k] = query[k];
        }
      });
      return Api.post('/order/list', { filter: {}, limit: 50, ...q });
    },
    { items: [] }
  );
}

export function useOrderData() {
  return useFetchApi(
    async (id: any) => Api.post('/order/one', { id }),
    { data: {} }
  );
}

export function useOrderInitDebug() {
  return useFetchApi(
    async (orderId: any) => Api.post('/log/request/one', { orderId }),
    { data: {} }
  );
}

export function useSeonChecks() {
  return useFetchApi(
    async (filter: any) => Api.post('/order/checks', { filter }),
    { items: [] }
  );
}

export function useUpdateOrderStatus(cb: any) {
  return useUpdateApi(
    async (type: 'approve' | 'reject', data: any, isProcessingOrExpired = false) => {
      const endpoint = isProcessingOrExpired
        ? '/order/approve-processing-or-expired'
        : `/order/${type}`;
      return Api.post(endpoint, data);
    },
    cb,
    'Status successfully updated',
    false,
    'Failed to update status'
  );
}

export function useResendOrderPostback(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/order/postback/retry', data),
    cb,
    'Postback resended successfully'
  );
}
