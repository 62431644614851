import React, { useState, useEffect, useRef } from 'react';

import { useHistory } from 'react-router';

import { Form } from 'react-final-form';

import clsx from 'clsx';

import { Waypoint } from 'react-waypoint';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Loader from '../../../components/Loader';
import EmptyList from '../../../components/EmptyList';
import BlackWhiteList from './BlackWhiteList';
import BlackWhiteFilter, { IBlackWhiteFilter } from './BlackWhiteFilter';
import CreateBWItemPopup from './BlackWhiteCreate';
import { Uploader } from '../../../components/Form';
import useGlobalStyles from '../../../components/Layout/useGlobalStyles';
import useColumns, { IColumn } from '../../../hooks/useColumns';
import useFilter from '../../../hooks/useFilter';
import { useAclList, ListType, useBWImport, IACLItem } from '../../../api/acl';
import SearchSubstr from '../../../components/SearchSubstr/SearchSubstr';
import { useBreakpoints } from '../../../hooks/useBreakpoints ';
import { makeStyles } from '@material-ui/core';
import FilterWrapper, { ICollapseHandle } from '../../../components/FilterWrapper/FilterWrapper';
import { Can } from '../../../acl/can';

const useStyles = makeStyles((theme) => ({
  root: {},
  card: {
    justifyContent: 'space-between',
    [theme.breakpoints.down(850)]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '15px',
    },
  },
  cardActionsMobile: {
    [theme.breakpoints.down(705)]: {
      flexDirection: 'column',
      gap: '15px',
      flexWrap: 'wrap',
      marginLeft: theme.spacing(-1),
    },
  },
  search: {
    marginBottom:'20px',
    [theme.breakpoints.down('sm')]: {
     paddingLeft: '12px',
    },
  }
}));

const defaultColumns: IColumn[] = [
  { id: 'id', name: 'ID', active: true },
  { id: 'type', name: 'Type', active: true },
  { id: 'value', name: 'Value', active: true },
  { id: 'black', name: 'Is blacklist', active: true },
  { id: 'createdAt', name: 'Created', active: true },
  { id: 'updatedAt', name: 'Updated', active: true },
  { id: 'action', name: '', active: true }
];

export default function ACLPage() {
  const classes = useGlobalStyles();
  const mobileClasses = useStyles();
  const isSm = useBreakpoints('sm');
  const list = window.location.pathname.split('/')[2];
  const [file, setFile] = useState();
  const accordionRef = useRef<ICollapseHandle>(null);
  const [search, setSearch] = useState('');
  const [{ items, error, full }, loading, load, update] = useAclList();
  const [columns, ColumnsSettings] = useColumns(`${list}list`, defaultColumns);
  const [setFilter, filter] = useFilter(`${list}list`);
  const [importData, importing] = useBWImport((res: any) => {
    if (res.success) {
      load({ filter });
    }
  });
  const history = useHistory();

  useEffect(() => {
    accordionRef.current?.collapse();
    load({ filter: { ...filter, list: list.toUpperCase() } });
  }, [list, filter]);

  const searchFilter = items.filter((item: IACLItem) =>
  item.value.toLowerCase().includes(search.toLowerCase())
);

  return (
    <Grid container spacing={3}>
      <FilterWrapper ref={accordionRef}>
        <Grid container alignItems="center">
            <Grid item xs={!isSm ? 10 : 12}><BlackWhiteFilter filter={filter} onChange={setFilter} /></Grid>
          {!isSm &&  <Grid item className={mobileClasses.search} xs={2}>
            <SearchSubstr search={search} setSearch={setSearch} label="Value" id="acl" />
          </Grid>}
          {isSm &&  <Grid item className={mobileClasses.search}>
            <SearchSubstr search={search} setSearch={setSearch} label="Value" id="acl" />
          </Grid>}
        </Grid>
      </FilterWrapper>
      <Grid item className={classes.table} style={{ width: '100%', minWidth: 'fit-content' }}>
        <Paper className={classes.paper}>
          <Can I="manage" a="acl">
            <CardActions className={mobileClasses.card}>
              <Form
                onSubmit={() => importData(file)}
                initialValues={{ file: null }}
                render={({ handleSubmit }) => (
                  <form onSubmit={handleSubmit} className={clsx(classes.cardActions, mobileClasses.cardActionsMobile)}>
                    <Uploader
                      onLoad={setFile}
                      input={{
                        accept: '.csv',
                        multiple: false,
                        name: 'file'
                      }}
                    />
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      className="import"
                      disabled={importing || !file}
                    >
                      Import
                    </Button>
                    <Button
                      onClick={() => history.push(`/acl/${list}/new`)}
                      variant="contained"
                      size="large"
                      className="import"
                    >
                      Add new
                    </Button>
                  </form>
                )}
              />
              <ColumnsSettings />
            </CardActions>
          </Can>
          {searchFilter.length
            ? <BlackWhiteList items={searchFilter} columns={columns} onDelete={update} />
            : <EmptyList loading={loading} error={error} />
          }
          <Loader loading={loading} />
          {!loading && !error && !full && <Waypoint onEnter={() => load({ cursor: items.length, filter: { ...filter, list: list.toUpperCase() } })} />}
        </Paper>
      </Grid>
      {window.location.pathname.includes('/new') && (
        <CreateBWItemPopup
          type={list.toUpperCase() as ListType}
          onSave={update}
          onClose={() => history.push(`/acl/${list}`)}
        />
      )}
    </Grid>
  );
}
