import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import TableFilter from '../../components/Filter';
import { BoolSelect, CurrencySelect, Input, Select } from '../../components/Form';
import Descriptor from '../../components/Descriptor';
import { OrderStatus } from '../../api/order';
import { composeValidators, maxLength, mustBeNumber } from '../../validators';
import { IKycCountry } from '../../api/kyc';
import { IApm, useApmList } from '../../api/apm';
import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';
import { Can } from '../../acl/can';
import { LegalEntityOne, useLegalEntityList } from '../../api/legalEntity';

const useStyles = makeStyles((theme) => ({
  root: {},
  selectDisable: {
    color: theme.palette.error.main
  },
  selectEnable: {
    color: 'inherit'
  },
}));

export interface IOrderFilter {
  dateFrom: Date | null;
  dateTo: Date | null;
  status: OrderStatus | null;
  currency: string | null;
  paymentCurrency: string | null;
  legalEntities: string[];
  externalId: string | null;
  orderId: string | null;
  requestedBy: string | null;
  acceptedBy: string | null;
  paymentMethod: string | null;
  orderType: string | null;
  instantPaymentScheme: boolean | null;
}

interface IOrdersFilterProps {
  filter: IOrderFilter,
  onChange: any,
  type?: 'page' | 'popup',
  setIsIgnoreDateFilter?: (dateIgnore: boolean)=> void,
  isIgnoreDateFilter?: boolean
}

export default function OrdersFilter({ filter, onChange, type = 'page', setIsIgnoreDateFilter, isIgnoreDateFilter }: IOrdersFilterProps) {
  const countries = useSelector((store: any) => store.appData.countries.data);
  const classes = useStyles();
  const [{ items: entities, error }, loadingEntity, loadEntity] = useLegalEntityList();
  const [{ items }, loading, load ] = useApmList();

  const orderTypes = [
    { value: 'FIAT_TO_CRYPTO', label: 'FIAT_TO_CRYPTO' },
    { value: 'WALLET_TO_FIAT', label: 'WALLET_TO_FIAT' },
    { value: 'INVOICE', label: 'Invoices' }
  ];

  useEffect(() => {
    load();
    loadEntity();
  }, []);

  const bankCard = { name: 'Bank card', externalId: 'CARD',enabled: true,id: Math.random() };

  const apmList = [...items, bankCard]
  ?.sort((a: IApm, b: IApm) => a.externalId.localeCompare(b.externalId))
  .sort((a: any, b: any) => b.enabled - a.enabled)
  .map((apm: IApm) => ({
    value: apm.externalId,
    label: apm.externalId==='CARD'? apm.name : apm.externalId,
    enabled: apm.enabled,
    className:  apm.enabled ? classes.selectEnable : classes.selectDisable
  }));

  return (
    <TableFilter onApply={onChange} filter={filter}>
      <Descriptor group="filters" title="clearsDate" >
        <Input
          name="orderId"
          label="Order ID"
          margin="none"
          validate={composeValidators(mustBeNumber, (v: any) => maxLength(v, 19))}
        />
      </Descriptor>
      {type === 'page' && (
        <>
          <Descriptor group="filters" title="clearsDate" >
            <Input
              name="externalId"
              label="External ID"
              margin="none"
            />
          </Descriptor>
          <Can I="view" a="order-details">
            <Input
              name="requestedBy"
              label="Requested by"
              margin="none"
            />
          </Can>
          <Input
            name="acceptedBy"
            label="Accepted by"
            margin="none"
          />
          <Select
            name="legalEntities"
            label="Legal Entity"
            options={entities.map((entity: LegalEntityOne) => ({ value: entity.id, label: entity.name }))}
            multi
          />
          <CurrencySelect
            name="currency"
            label="Currency"
          />
          <CurrencySelect
            name="paymentCurrency"
            label="Payment currency"
            multi
          />
          <Descriptor group="filters" title="multi">
            <Select
              name="status"
              label="Status"
              options={Object.keys(OrderStatus).map((s) => ({ value: s, label: s }))}
              multi
              fullWidth
            />
          </Descriptor>
          <Descriptor group="filters" title="multi">
            <Select
              name="country"
              label="Country"
              options={countries?.map((country: IKycCountry) => ({ value: country.code, label: country.name }))}
              multi
              fullWidth
            />
          </Descriptor>
          <Descriptor group="filters" title="multi">
            <Select
              name="paymentMethod"
              label="Payment Method"
              options={apmList}
              isNowrap
              multi
              fullWidth
            />
          </Descriptor>
          <Descriptor group="filters" title="multi">
            <Select
              name="orderType"
              label="Order Type"
              options={orderTypes.map((type: { value: string, label: string }) => ({ value: type.value, label: type.label }))}
              multi
              fullWidth
            />
          </Descriptor>
          <Input
            name="sid"
            label="SID"
            margin="none"
          />
          <BoolSelect
            name="instantPaymentScheme"
            label="Instant Payment Scheme"
            fullWidth
          />

          {setIsIgnoreDateFilter &&
            <FormControlLabel
              control={<Checkbox checked={isIgnoreDateFilter} onChange={() => setIsIgnoreDateFilter(!isIgnoreDateFilter)} />}
              label="Ignore filter by Date"
            />}
        </>
          )}
    </TableFilter>
  );
}
