import React, { useEffect } from 'react';

import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';

import TableFilter from '../../components/Filter';
import { AutocompleteFieldWithTags, Input, Select } from '../../components/Form';
import { SettlementStatus } from '../../api/settlements';
import { LegalEntityOne, useLegalEntityList } from '../../api/legalEntity';

const useStyles = makeStyles(() => ({
  root: {
    '& > *': {
      marginTop: 0,
    },
  }
}));

export interface ISettlementsFilter {
  dateFrom: Date | null;
  dateTo: Date | null;
  customerIds: string[] | null;
  legalEntities: string[];
  id: string | null;
  status: string | null;
}

interface IProps{
  filter: ISettlementsFilter,
  onChange: any,
  setIsIgnoreDateFilter?: (dateIgnore: boolean)=> void,
  isIgnoreDateFilter?: boolean
}

export default function SettlementsFilter({ filter, onChange, setIsIgnoreDateFilter, isIgnoreDateFilter }: IProps){
  const [{ items: entities, error }, loadingEntity, loadEntity] = useLegalEntityList();
  const classes = useStyles();

  useEffect(() => {
    loadEntity();
  }, []);

  return (
    <TableFilter onApply={onChange} filter={filter} className={classes.root}>
        <Input
          name="id"
          label="Settlement ID"
          margin="none"
        />
        <AutocompleteFieldWithTags
          name="customerIds"
          label="Merchant IDs"
        />
        <Select
          name="status"
          label="Status"
          multi
          fullWidth
          options={Object.keys(SettlementStatus).map((t) => ({ value: t, label: t }))}
        />
        <Select
          name="legalEntities"
          label="Legal Entity"
          options={entities.map((entity: LegalEntityOne) => ({ value: entity.id, label: entity.name }))}
          multi
        />
        {setIsIgnoreDateFilter &&
          <FormControlLabel
            control={
              <Checkbox
                checked={isIgnoreDateFilter}
                onChange={() => setIsIgnoreDateFilter(!isIgnoreDateFilter)}
              />}
            label="Ignore filter by Date"
        />}
    </TableFilter>
  );
}
