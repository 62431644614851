import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import CachedIcon from '@material-ui/icons/Cached';
import BorderClearIcon from '@material-ui/icons/BorderClear';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import PaymentIcon from '@material-ui/icons/Payment';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import RoomIcon from '@material-ui/icons/Room';
import SettingsIcon from '@material-ui/icons/Settings';
import BlockIcon from '@material-ui/icons/Block';
import { Apps } from '@material-ui/icons';
import BusinessIcon from '@material-ui/icons/Business';
import MoneyIcon from '@material-ui/icons/AccountBalance';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import { Can } from '../../acl/can';

const useStyles = makeStyles((theme) => ({
  root: {
    overflowY: 'auto',
  },
  activeItem: {
    background: theme.palette.action.selected,
  },
  expandable: {
    flexDirection: 'column',
    cursor: 'pointer',
    alignItems: 'stretch',
    padding: 0,
  },
  submenu: {
    marginLeft: -theme.spacing(2),
    marginRight: -theme.spacing(2),
    '& > a': {
      paddingLeft: 87,
    },
  },
}));

interface IMainMenu {
  onClose: () => void;
}

export const MainMenu = ({ onClose }: IMainMenu) => {
  const classes = useStyles();
  const url = window.location.pathname;
  const [submenu, setSubmenu] = useState<string | undefined>(url.split('/')[1]);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  const closeMobileMenu = () => {
    if (isXs) {
      onClose();
    }
  };
  return (
    <List className={classes.root}>
      <Can I="read" a="settings">
        <ListItem className={classes.expandable}>
          <ListItem button component="div" onClick={() => setSubmenu('settings')}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItem>
          {submenu === 'settings' && (
            <div className={classes.submenu}>
              <ListItem
                button
                component={NavLink}
                to={`${url}/global-settings`}
                onClick={closeMobileMenu}
                className={
                  url.includes('/global-settings') ? classes.activeItem : ''
                }
              >
                <ListItemText primary="Global settings" />
              </ListItem>
              <ListItem
                button
                component={NavLink}
                to="/payment-providers"
                onClick={closeMobileMenu}
                className={
                  url.includes('/payment-providers') ? classes.activeItem : ''
                }
              >
                <ListItemText primary="Payment providers" />
              </ListItem>
              <ListItem
                button
                component={NavLink}
                to="/settings/apm"
                onClick={closeMobileMenu}
                className={
                  url.includes('/settings/apm') ? classes.activeItem : ''
                }
              >
                <ListItemText primary="APM" />
              </ListItem>
              <ListItem
                button
                component={NavLink}
                to="/settings/payment-groups"
                onClick={closeMobileMenu}
                className={url.includes('/settings/payment-groups') ? classes.activeItem : ''}
              >
                <ListItemText primary="Payment groups" />
              </ListItem>
              <ListItem
                button
                component={NavLink}
                to="/settings/payment-methods"
                onClick={closeMobileMenu}
                className={url.includes('/settings/payment-methods') ? classes.activeItem : ''}
              >
                <ListItemText primary="Payment methods" />
              </ListItem>
              <ListItem
                button
                component={NavLink}
                to="/settings/kyc-limits"
                onClick={closeMobileMenu}
                className={
                  url.includes('/settings/kyc-limits') ? classes.activeItem : ''
                }
              >
                <ListItemText primary="KYC Limits" />
              </ListItem>
              <ListItem
                button
                component={NavLink}
                to="/settings/gateway-configs"
                onClick={closeMobileMenu}
                className={
                  url.includes('/settings/gateway-configs')
                    ? classes.activeItem
                    : ''
                }
              >
                <ListItemText primary="Gateways Configs" />
              </ListItem>
              <ListItem
                button
                component={NavLink}
                to="/settings/obi-reports"
                onClick={closeMobileMenu}
                className={
                  url.includes('/settings/obi-reports')
                    ? classes.activeItem
                    : ''
                }
              >
                <ListItemText primary="Business name" />
              </ListItem>
            </div>
          )}
        </ListItem>
      </Can>
      <Can I="read" a="kyc">
        <ListItem
          button
          component={NavLink}
          to="/kyc"
          onClick={closeMobileMenu}
          className={
            url.includes('/kyc') && !url.includes('/kyc-')
              ? classes.activeItem
              : ''
          }
        >
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary="KYC" />
        </ListItem>
      </Can>
      <Can I="read" a="customers">
        <ListItem
          button
          component={NavLink}
          to="/customers"
          onClick={closeMobileMenu}
          className={url.includes('/customers') ? classes.activeItem : ''}
        >
          <ListItemIcon>
            <PeopleAltIcon />
          </ListItemIcon>
          <ListItemText primary="Customers" />
        </ListItem>
      </Can>
      <Can I="read" a="orders">
        <ListItem
          button
          component={NavLink}
          to="/orders"
          onClick={closeMobileMenu}
          className={url.includes('/orders') ? classes.activeItem : ''}
        >
          <ListItemIcon>
            <ReceiptIcon />
          </ListItemIcon>
          <ListItemText primary="Orders" />
        </ListItem>
      </Can>
      <Can I="read" a="payments">
        <ListItem
          button
          component={NavLink}
          to="/payments"
          onClick={closeMobileMenu}
          className={url.includes('/payments') ? classes.activeItem : ''}
        >
          <ListItemIcon>
            <PaymentIcon />
          </ListItemIcon>
          <ListItemText primary="Payments" />
        </ListItem>
      </Can>
      <Can I="read" a="clearing">
        <ListItem
          button
          component={NavLink}
          to="/clearing"
          onClick={closeMobileMenu}
          className={url.includes('/clearing') ? classes.activeItem : ''}
        >
          <ListItemIcon>
            <CachedIcon />
          </ListItemIcon>
          <ListItemText primary="Clearing" />
        </ListItem>
      </Can>
      <Can I="read" a="blockchain">
        <ListItem
          button
          component={NavLink}
          to="/blockchain"
          onClick={closeMobileMenu}
          className={url.includes('/blockchain') ? classes.activeItem : ''}
        >
          <ListItemIcon>
            <BorderClearIcon />
          </ListItemIcon>
          <ListItemText primary="Blockchain TXs" />
        </ListItem>
      </Can>
      <Can I="read" a="api-keys">
        <ListItem
          button
          component={NavLink}
          to="/widget-keys"
          onClick={closeMobileMenu}
          className={url.includes('/widget-keys') ? classes.activeItem : ''}
        >
          <ListItemIcon>
            <VpnKeyIcon />
          </ListItemIcon>
          <ListItemText primary="API keys" />
        </ListItem>
      </Can>
      <Can I="read" a="currencies">
        <ListItem
          button
          component={NavLink}
          to="/currencies"
          onClick={closeMobileMenu}
          className={url.includes('/currencies') ? classes.activeItem : ''}
        >
          <ListItemIcon>
            <AttachMoneyIcon />
          </ListItemIcon>
          <ListItemText primary="Currencies" />
        </ListItem>
      </Can>
      <Can I="read" a="wallets">
        <ListItem
          button
          component={NavLink}
          to="/wallets"
          onClick={closeMobileMenu}
          className={url.includes('/wallets') ? classes.activeItem : ''}
        >
          <ListItemIcon>
            <AccountBalanceWalletIcon />
          </ListItemIcon>
          <ListItemText primary="Wallets" />
        </ListItem>
      </Can>
      <Can I="read" a="wallet-operations">
        <ListItem
          button
          component={NavLink}
          to="/wallet-operations"
          onClick={closeMobileMenu}
          className={url.includes('/wallet-operations') ? classes.activeItem : ''}
        >
          <ListItemIcon>
            <SwapHorizIcon />
          </ListItemIcon>
          <ListItemText primary="Wallet operations" />
        </ListItem>
      </Can>
      <Can I="read" a="kyc-countries">
        <ListItem
          button
          component={NavLink}
          to="/kyc-countries"
          onClick={closeMobileMenu}
          className={url.includes('/kyc-countries') ? classes.activeItem : ''}
        >
          <ListItemIcon>
            <RoomIcon />
          </ListItemIcon>
          <ListItemText primary="KYC countries" />
        </ListItem>
      </Can>
      <Can I="read" a="acl">
        <ListItem className={classes.expandable}>
          <ListItem
            button
            component={NavLink}
            to="/acl/black"
            onClick={() => setSubmenu('acl')}
          >
            <ListItemIcon>
              <BlockIcon />
            </ListItemIcon>
            <ListItemText primary="ACL" />
          </ListItem>
          {submenu === 'acl' && (
            <div className={classes.submenu}>
              <ListItem
                button
                component={NavLink}
                to="/acl/black"
                onClick={closeMobileMenu}
                className={url.includes('/acl/black') ? classes.activeItem : ''}
              >
                <ListItemText primary="Blacklist" />
              </ListItem>
              <ListItem
                button
                component={NavLink}
                to="/acl/white"
                onClick={closeMobileMenu}
                className={url.includes('/acl/white') ? classes.activeItem : ''}
              >
                <ListItemText primary="Whitelist" />
              </ListItem>
              <Can I="read" a="acl-cars">
                <ListItem
                  button
                  component={NavLink}
                  to="/acl/cards"
                  onClick={closeMobileMenu}
                  className={url.includes('/acl/cards') ? classes.activeItem : ''}
                >
                  <ListItemText primary="Cards controller" />
                </ListItem>
              </Can>
              <ListItem
                button
                component={NavLink}
                to="/acl/bin-codes"
                onClick={closeMobileMenu}
                className={
                  url.includes('/acl/bin-codes') ? classes.activeItem : ''
                }
              >
                <ListItemText primary="BIN codes" />
              </ListItem>
            </div>
          )}
        </ListItem>
      </Can>
      <Can I="read" a="admin">
        <ListItem className={classes.expandable}>
          <ListItem
            button
            component={NavLink}
            to="/admins/list"
            onClick={() => setSubmenu('admins')}
          >
            <ListItemIcon>
              <SupervisorAccountIcon />
            </ListItemIcon>
            <ListItemText primary="Admins" />
          </ListItem>
          {submenu === 'admins' && (
            <div className={classes.submenu}>
              <ListItem
                button
                component={NavLink}
                to="/admins/list"
                onClick={closeMobileMenu}
                className={url.includes('/admins/list') ? classes.activeItem : ''}
              >
                <ListItemText primary="Admins list" />
              </ListItem>
              <ListItem
                button
                component={NavLink}
                to="/admins/activities"
                onClick={closeMobileMenu}
                className={
                  url.includes('/admins/activities') ? classes.activeItem : ''
                }
              >
                <ListItemText primary="Admins activities" />
              </ListItem>
              {/*<ListItem*/}
              {/*  button*/}
              {/*  component={NavLink}*/}
              {/*  to="/admins/authorities"*/}
              {/*  className={*/}
              {/*    url.includes("/admins/authorities") ? classes.activeItem : ""*/}
              {/*  }*/}
              {/*>*/}
              {/*  <ListItemText primary="Authorities" />*/}
              {/*</ListItem>*/}
            </div>
          )}
        </ListItem>
      </Can>
      <Can I="read" a="rolling">
        <ListItem
          button
          component={NavLink}
          to="/rolling"
          onClick={closeMobileMenu}
          className={url.includes('/rolling') ? classes.activeItem : ''}
        >
          <ListItemIcon>
            <RotateLeftIcon />
          </ListItemIcon>
          <ListItemText primary="Rolling reserve" />
        </ListItem>
      </Can>
      <Can I="read" a="webflow">
        <ListItem
          button
          component={NavLink}
          to="/webflow"
          onClick={closeMobileMenu}
          className={url.includes('/webflow') ? classes.activeItem : ''}
        >
          <ListItemIcon>
            <SwapHorizIcon />
          </ListItemIcon>
          <ListItemText primary="Update Webflow" />
        </ListItem>
      </Can>
      <Can I="read" a="legal-entity">
        <ListItem
          button
          component={NavLink}
          to="/legal-entity"
          onClick={closeMobileMenu}
          className={url.includes('/legal-entity') ? classes.activeItem : ''}
        >
          <ListItemIcon>
            <BusinessIcon />
          </ListItemIcon>
          <ListItemText primary="Legal Entity" />
        </ListItem>
      </Can>
      <Can I="read" a="settlements">
        <ListItem
          button
          component={NavLink}
          to="/settlements"
          onClick={closeMobileMenu}
          className={url.includes('/settlements') ? classes.activeItem : ''}
        >
          <ListItemIcon>
            <MoneyIcon />
          </ListItemIcon>
          <ListItemText primary="Settlements" />
        </ListItem>
      </Can>
      <Can I="read" a="payment-gateway">
        <ListItem className={classes.expandable}>
          <ListItem
            button
            component={NavLink}
            to="/payment-gateway/provider-settings"
            onClick={() => setSubmenu('payment-gateway')}
          >
            <ListItemIcon>
              <SettingsApplicationsIcon />
            </ListItemIcon>
            <ListItemText primary="Payment Gateway" />
          </ListItem>
          {submenu === 'payment-gateway' && (
            <div className={classes.submenu}>
              <ListItem
                button
                component={NavLink}
                to="/payment-gateway/provider-settings"
                onClick={closeMobileMenu}
                className={url.includes('/payment-gateway/provider-settings') ? classes.activeItem : ''}
              >
                <ListItemText primary="Provider Settings" />
              </ListItem>
            </div>
          )}
        </ListItem>
      </Can>
      <Can I="read" a="other">
        <ListItem className={classes.expandable}>
          <ListItem
            button
            component={NavLink}
            to="/other/oly"
            onClick={() => setSubmenu('other')}
          >
            <ListItemIcon>
              <Apps />
            </ListItemIcon>
            <ListItemText primary="Other" />
          </ListItem>
          {submenu === 'other' && (
            <div className={classes.submenu}>
              <ListItem
                button
                component={NavLink}
                to="/other/oly"
                onClick={closeMobileMenu}
                className={url.includes('/other/oly') ? classes.activeItem : ''}
              >
                <ListItemText primary="Oly invoices" />
              </ListItem>
              {/* WW-635 Hide api requests section */}
              <ListItem
                button
                component={NavLink}
                to="/other/api-requests"
                className={url.includes('/other/api-requests') ? classes.activeItem : ''}
              >
                <ListItemText primary="Api requests" />
              </ListItem>
              <ListItem
                button
                component={NavLink}
                to="/other/tracing"
                onClick={closeMobileMenu}
                className={url.includes('/other/tracing') ? classes.activeItem : ''}
              >
                <ListItemText primary="Tracing" />
              </ListItem>
            </div>
          )}
        </ListItem>
      </Can>
    </List>
  );
};
